import React from 'react'
import { Edit, SimpleForm, BooleanInput } from 'react-admin'

const CustomEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect='show'>
      <BooleanInput source='paid' />
    </SimpleForm>
  </Edit>
)

const edit = props => <CustomEdit {...props} />

export default edit
