import React from 'react'
import {
  Show,
  TextField,
  Tab,
  TabbedShowLayout,
  NumberField,
  DateField,
  BooleanField,
  ReferenceField,
  ArrayField,
  Datagrid,
  FunctionField,
  useTranslate,
} from 'react-admin'
import { UserDocuments, UserTransactions } from '../../../organisms'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { COUNTRY_CODES, NATURAL_PERSON_TYPE, LEGAL_PERSON_TYPE } from '../../../../config/constants'

const CustomShow = props => {
  const translate = useTranslate()
  const userId = _get(props, 'match.params.id', '')

  const getPersonType = type => {
    if (type === NATURAL_PERSON_TYPE) {
      return translate('resources.users.fields.personTypeNatural')
    } else if (type === LEGAL_PERSON_TYPE) {
      return translate('resources.users.fields.personTypeLegal')
    }
    return ''
  }

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={translate('resources.users.fields.data')}>
          <TextField source='id' />
          <TextField source='name' />
          <TextField source='nickname' />
          <TextField source='email' />
          <DateField source='birthdate' />
          <TextField source='location' />
          <TextField source='country' />
          <NumberField source='availableVideos' />
          <BooleanField source='publisher' />
          <BooleanField source='freeVideos' />
          <TextField source='creatorId' />
          <BooleanField source='enabled' />
          <BooleanField source='banned' />
          <BooleanField source='agency' />
          <BooleanField source='featured' />
          <DateField source='createdAt' />
          <DateField source='updatedAt' />
          <TextField source='referralCode' />
          <ReferenceField
            label={translate('resources.users.fields.referredBy')}
            link='show'
            source='referredBy.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>

          <ReferenceField
            label={translate('resources.users.fields.invitedBy')}
            link='show'
            source='invitedBy.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>
          <TextField source='invoiceCount' />
        </Tab>
        <Tab label={translate('resources.users.fields.billingInfo')}>
          <FunctionField
            source='billingInfo.personType'
            label={translate('resources.users.fields.personType')}
            render={(record, source) => {
              const personType = _get(record, source)
              return getPersonType(personType)
            }}
          />
          <TextField source='billingInfo.name' />
          <TextField source='billingInfo.lastName' />
          <FunctionField
            source='billingInfo.residenceCountry'
            render={(record, source) => {
              const countryId = _get(record, source, '')
              const findCountry = _find(COUNTRY_CODES, { id: countryId })

              return findCountry?.name
            }}
          />
          <TextField source='billingInfo.residenceAddress' />
          <TextField source='billingInfo.residenceCity' />
          <TextField source='billingInfo.residencePostalCode' />
          <DateField source='billingInfo.birthDate' />
          <FunctionField
            source='billingInfo.documentType'
            render={(record, source) => {
              const documentType = _get(record, source, '')
              if (documentType) {
                return translate(`resources.users.fields.${documentType}`)
              }

              return ''
            }}
          />

          <TextField
            label={translate('resources.users.fields.billingInfo.photoDocument')}
            source='billingInfo.photoDocument.description'
          />
          <TextField
            label={translate('resources.users.fields.billingInfo.holdingPhotoDocument')}
            source='billingInfo.holdingPhotoDocument.description'
          />

          <TextField
            label={translate('resources.users.fields.billingInfo.bankCertificateDocument')}
            source='billingInfo.bankCertificateDocument.description'
          />
          <TextField
            label={translate('resources.users.fields.billingInfo.companyCertificateDocument')}
            source='billingInfo.companyCertificateDocument.description'
          />

          <TextField source='billingInfo.documentNumber' />
          <TextField source='billingInfo.companyName' />
          <TextField source='billingInfo.companyEmail' />
          <TextField source='billingInfo.companyCity' />
          <TextField source='billingInfo.companyAddress' />
          <TextField source='billingInfo.companyPostalCode' />
          <FunctionField
            source='billingInfo.companyCountry'
            render={(record, source) => {
              const countryId = _get(record, source, '')
              const findCountry = _find(COUNTRY_CODES, { id: countryId })

              return findCountry?.name
            }}
          />
          <TextField source='billingInfo.companyVat' />
          <TextField source='billingInfo.companyIban' />
          <DateField source='billingInfo.contractSignDate' />
          <BooleanField source='billingInfo.checked' />
        </Tab>
        <Tab label={translate('resources.users.fields.transactionTab')}>
          <UserTransactions userId={userId} />
        </Tab>
        <Tab label={translate('resources.users.fields.contributions')}>
          <ArrayField source='contributions'>
            <Datagrid>
              <ReferenceField link='show' source='beneficiaryId' reference='beneficiaries'>
                <TextField source='nameEs' />
              </ReferenceField>
              <NumberField source='percentage' />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label={translate('resources.users.fields.invitations')}>
          <TextField source='invitationCode' />
          <NumberField source='invitationCount' />
          <NumberField source='usersInvitedCount' />
        </Tab>

        <Tab label={translate('resources.users.fields.files')}>
          <UserDocuments id={userId} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const show = props => <CustomShow {...props} />

export default show
