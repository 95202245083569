import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { RegularInput, RegularButton } from '../../atoms'
import { withTranslate, useRedirect, useNotify } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import _ from 'lodash'
import { isAdminUser, isSuperAdminUser } from '../../../utils'
import jwtDecode from 'jwt-decode'
import { Auth } from 'aws-amplify'

const ForgotEmailForm = ({ translate }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [code, setCode] = useState('------')
  const [newPassword, setNewPassword] = useState('')
  const [disableCodeFormButton, setDisableCodeFormButton] = useState(true)
  const [disabledButton, setDisabledButton] = useState(true)
  const redirect = useRedirect()
  const notify = useNotify()

  useEffect(() => {
    if (userEmail) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [userEmail])

  useEffect(() => {
    if (code && newPassword) {
      setDisableCodeFormButton(false)
    } else {
      setDisableCodeFormButton(true)
    }
  }, [code, newPassword])

  const onSubmitEmail = async () => {
    try {
      setIsFetching(true)
      await Auth.forgotPassword(userEmail)
      setEmailSend(true)
    } catch {
      notify('generalError', 'warning')
    } finally {
      setIsFetching(false)
    }
  }

  const onSubmitCode = async () => {
    try {
      setIsFetching(true)
      await Auth.forgotPasswordSubmit(userEmail, code, newPassword)
      notify('forgotEmailForm.passwordChanged')
      const loginResponse = await Auth.signIn({ username: userEmail, password: newPassword })
      const token = _.get(loginResponse, 'signInUserSession.accessToken.jwtToken', '')

      if (token) {
        const decoded = jwtDecode(token)
        const permissions = _.get(decoded, 'cognito:groups')
        const isRoleAdmin = isAdminUser(permissions)
        const isRoleSuperAdmin = isSuperAdminUser(permissions)

        if (isRoleAdmin || isRoleSuperAdmin) {
          localStorage.setItem('token', token)
        }
      }

      goToLogin()
    } catch {
      notify('generalError', 'warning')
    } finally {
      setIsFetching(false)
    }
  }

  const goToLogin = () => {
    redirect('login')
  }

  const handleChangeUser = value => {
    setUserEmail(value)
  }

  const handleChangeCode = value => {
    setCode(value)
  }

  const handleChangePassword = value => {
    setNewPassword(value)
  }

  return (
    <div>
      <Typography style={styles.title} variant='body1' component='p'>
        {translate('forgotEmailForm.title')}
      </Typography>
      {emailSend ? (
        <form onSubmit={onSubmitCode} autoComplete='off'>
          <RegularInput value={code} onChange={handleChangeCode} label={translate('forgotEmailForm.code')} />
          <RegularInput
            value={newPassword}
            onChange={handleChangePassword}
            label={translate('forgotEmailForm.newPassword')}
            type='password'
          />
          <div style={styles.btnsWrapper}>
            <div style={styles.btnWrapper}>
              <RegularButton
                isFetching={isFetching}
                disabled={disableCodeFormButton}
                type='submit'
                label={translate('forgotEmailForm.send')}
              />
            </div>
          </div>
        </form>
      ) : (
        <form onSubmit={onSubmitEmail} autoComplete='off'>
          <RegularInput onChange={handleChangeUser} label={translate('forgotEmailForm.user')} />
          <div style={styles.btnsWrapper}>
            <div style={styles.btnWrapper}>
              <RegularButton
                isFetching={isFetching}
                disabled={disabledButton}
                type='submit'
                label={translate('forgotEmailForm.send')}
              />
            </div>
          </div>
        </form>
      )}
      <div style={styles.backWrapper}>
        <Typography onClick={goToLogin} style={styles.back} variant='body1' component='span'>
          {translate('forgotEmailForm.back')}
        </Typography>
      </div>
    </div>
  )
}

ForgotEmailForm.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withStyles(styles), withTranslate)

export default enharce(ForgotEmailForm)
