import React from 'react'
import { Create, SimpleForm } from 'react-admin'
import { CreateFields } from '../commons'

const create = props => (
  <Create {...props}>
    <SimpleForm redirect='show'>
      {CreateFields()}
    </SimpleForm>
  </Create>
)

export default create
