import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { ChangeUserPassForm, ChangeNameForm } from '../../organisms'
import { withTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const MyProfile = ({ translate }) => {
  return (
    <div style={styles.root}>
      <Typography style={styles.pageTitle} variant='h5' color='inherit'>
        {translate('myProfile.title')}
      </Typography>
      <div style={styles.formWrapper}>
        <ChangeNameForm />
      </div>
      <div style={styles.formWrapper}>
        <ChangeUserPassForm />
      </div>
    </div>
  )
}

MyProfile.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withTranslate, withStyles(styles))

export default enharce(MyProfile)
