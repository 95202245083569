import React, { useEffect } from 'react'
import {
  Datagrid,
  TextField,
  List,
  DateField,
  FunctionField,
  ReferenceField,
  withTranslate,
  useListParams,
  useRedirect,
  BooleanField,
  EditButton,
} from 'react-admin'
import _get from 'lodash/get'
import _sumBy from 'lodash/sumBy'
import { priceFormatter } from '../../../../utils'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import CustomFilters from './filters'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }
const PUBLISHER_DONATION = 'publisher_donation'
const USER_DONATION = 'user_donation'

const postRowClick = (id, basePath, filter, redirect) => {
  localStorage.setItem('beneficiaryFilter', JSON.stringify(filter))
  redirect(`${basePath}/${id}/show`)
}

const MyList = ({ translate, ...props }) => {
  const listParams = useListParams(props)
  const redirect = useRedirect()
  const filter = _get(listParams, '[0].filter', {})

  useEffect(() => {
    localStorage.removeItem('beneficiaryFilter')
  }, [])

  return (
    <List
      {...props}
      sort={DEFAULT_SORT}
      filters={<CustomFilters translate={translate} />}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={(id, basePath) => postRowClick(id, basePath, filter, redirect)}>
        <TextField source='id' sortable={false} />
        <ReferenceField
          sortable={false}
          label={translate('resources.beneficiary-earnings.fields.beneficiarie')}
          link='show'
          source='id'
          reference='beneficiaries'
        >
          <TextField source='nameEs' />
        </ReferenceField>
        <TextField source='publisherCount' />

        <FunctionField
          sortable={false}
          label={translate('resources.beneficiary-earnings.fields.publisherDonation')}
          source='items'
          render={(record, source) => {
            const items = _get(record, source, '')
            const total = _sumBy(items, n => n.type === PUBLISHER_DONATION && n.amount)
            return priceFormatter(total / 100)
          }}
        />

        <TextField source='userCount' />

        <FunctionField
          sortable={false}
          label={translate('resources.beneficiary-earnings.fields.userDonation')}
          source='items'
          render={(record, source) => {
            const items = _get(record, source, '')
            const total = _sumBy(items, n => n.type === USER_DONATION && n.amount)
            return priceFormatter(total / 100)
          }}
        />

        <FunctionField
          sortable={false}
          source='total'
          render={(record, source) => {
            let price = _get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />

        <DateField source='periodStart' />
        <DateField source='periodEnd' />
        <BooleanField source='paid' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const list = props => <MyList {...props} />

MyList.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(list)
