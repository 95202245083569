import React from 'react'
import {
  TextField,
  List,
  downloadCSV,
  withTranslate,
  NumberField,
  DateField,
  FunctionField,
} from 'react-admin'
import compose from 'recompose/compose'
import { priceFormatter } from '../../../../utils'
import _get from 'lodash/get'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import moment from 'moment'
import ListActions from './actions'
import jsonExport from 'jsonexport/dist'
import { COUNTRY_CODES } from '../../../../config/constants'
import CustomFilters from './filters'
import CustomizableDatagrid from 'ra-customizable-datagrid';
import CurrencyField from '../../../atoms/currencyField'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const MyList = ({ translate, ...props }) => {
  const exporter = posts => {
    const postsForExport = posts.map(post => {
      const { countryCode } = post
      const findCountry = _find(COUNTRY_CODES, { id: countryCode })

      return {
        [translate('resources.transactions.fields.createdAt')]:
          post?.createdAt && moment(post?.createdAt).format('DD/MM/YYYY'),
        [translate('resources.transactions.fields.id')]: post?.id,
        [translate('resources.transactions.fields.user.id')]: post?.user?.id,
        [translate('resources.transactions.fields.store')]: post?.store,
        [translate('resources.transactions.fields.currencyCode')]: post?.currencyCode,
        [translate('resources.transactions.fields.countryCode')]: findCountry?.name,
        [translate('resources.transactions.fields.videosAmount')]: post?.videosAmount,
        [translate('resources.transactions.fields.moneyAmount')]: priceFormatter(post?.moneyAmount / 100),
        [translate('resources.transactions.fields.status')]: post?.status,
        [translate('resources.transactions.fields.donationAmount')]: priceFormatter(post?.donationAmount / 100),
        [translate('resources.transactions.fields.beneficiary.nameEs')]: post?.beneficiary?.nameEs,
      }
    })

    jsonExport(
      postsForExport,
      (err, csv) => downloadCSV(csv, 'ingresos') // download as 'ingresos.csv` file
    )
  }

  return (
    <List
      {...props}
      sort={DEFAULT_SORT}
      filters={<CustomFilters translate={translate} />}
      bulkActionButtons={false}
      exporter={exporter}
      actions={<ListActions />}
    >
      <CustomizableDatagrid rowClick={null}>
        <DateField showTime source='createdAt' />
        <TextField source='id' />
        <TextField source='user.id' />
        <TextField source='store' />
        <TextField source='currencyCode' />
        <TextField source='countryCode' />
        <NumberField source='videosAmount' />
        <NumberField source="pricingCredits" />
        <NumberField source='remainingCredits' />
        <FunctionField
          source='moneyAmount'
          render={(record, source) => {
            let price = _get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />
        <TextField source='status' />
        <FunctionField
          source='donationAmount'
          render={(record, source) => {
            let price = _get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />

        <TextField source='beneficiary.nameEs' />
        <CurrencyField source="pricingPrice" currencySource="currencyCode" />
        <CurrencyField source="pricingProceeds" currencySource="currencyCode" />
        <CurrencyField source="pricingPricePerCredit" currencySource="currencyCode" />
        <CurrencyField source="pricingProceedsPerCredit" currencySource="currencyCode" />
        <CurrencyField source="pricingCreatorSharePerCredit" currencySource="currencyCode" />
        <CurrencyField source="pricingPartnerSharePerCredit" currencySource="currencyCode" />
      </CustomizableDatagrid>
    </List>
  )
}

MyList.propTypes = {
  translate: PropTypes.any,
}

const list = props => <MyList {...props} />

const enharced = compose(withTranslate)

export default enharced(list)
