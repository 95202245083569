import React from 'react'
import { Filter, DateInput } from 'react-admin'
import { makeStyles, Chip } from '@material-ui/core'
import PropTypes from 'prop-types'

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}))

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={label} />
}

QuickFilter.propTypes = {
  label: PropTypes.string,
}

const CustomFilters = ({ translate, ...props }) => (
  <Filter {...props}>
    <DateInput
      label={translate('resources.transactions.fields.startDate')}
      source='createdAt||gte'
      autoComplete='off'
    />
    <DateInput label={translate('resources.transactions.fields.endDate')} source='createdAt||lte' autoComplete='off' />
  </Filter>
)

CustomFilters.propTypes = {
  translate: PropTypes.func,
}

export default CustomFilters
