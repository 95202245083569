import React from 'react'
import { DeleteButton, SaveButton, Toolbar, withTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
const EditToolbar = ({ deleteEnabled = true, ...props }) => {
  return (
    <Toolbar {...props} classes={useStyles()}>
      <SaveButton />
      {deleteEnabled && <DeleteButton />}
    </Toolbar>
  )
}

EditToolbar.propTypes = {
  translate: PropTypes.func,
  deleteEnabled: PropTypes.bool,
}

export default withTranslate(EditToolbar)
