import React from 'react'
import { Edit, SimpleForm, BooleanInput } from 'react-admin'

const edit = props => {
  return (
    <Edit {...props}>
      <SimpleForm redirect='list'>
        <BooleanInput source='paid' />
      </SimpleForm>
    </Edit>
  )
}

export default edit
