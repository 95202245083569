import React from 'react'
import { Login, LoginForm } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import { withTranslate, useRedirect } from 'react-admin'
import compose from 'recompose/compose'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'

const CustomLoginPage = ({ translate, ...props }) => {
  const redirect = useRedirect()

  return (
    <Login {...props} backgroundImage={null}>
      <LoginForm />
      <div style={styles.backWrapper}>
        <Typography onClick={() => redirect('forgot-password')} style={styles.back} variant='body1' component='span'>
          {translate('customLoginPage.forgotPass')}
        </Typography>
      </div>
    </Login>
  )
}

CustomLoginPage.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withStyles(styles), withTranslate)

export default enharce(CustomLoginPage)
