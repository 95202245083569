import React from 'react'
import { Datagrid, TextField, List, EditButton, DateField, FunctionField } from 'react-admin'
import _ from 'lodash'
import { priceFormatter } from '../../../../utils'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const list = props => (
  <List {...props} sort={DEFAULT_SORT} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <FunctionField
        source='price'
        render={(record, source) => {
          let price = _.get(record, source, '')
          price = price / 100
          return priceFormatter(price)
        }}
      />
      <TextField source='appStoreId' />
      <TextField source='playStoreId' />
      <TextField source='videosAmount' />
      <TextField source='freeVideosAmount' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
      <EditButton />
    </Datagrid>
  </List>
)

export default list
