import React from 'react'
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin'

const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' />
      <TextField source='code' />
      <TextField source='nameEs' />
      <TextField source='nameEn' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
    </SimpleShowLayout>
  </Show>
)

export default show
