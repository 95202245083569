import React from 'react'
import { Filter, TextInput, BooleanInput, DateInput, SelectInput } from 'react-admin'
import { makeStyles, Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import _orderBy from 'lodash/orderBy'
import { COUNTRY_CODES } from '../../../../config/constants'

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}))

const QuickFilter = ({ label }) => {
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={label} />
}

QuickFilter.propTypes = {
  label: PropTypes.string,
}

const formatCountryCodes = _orderBy(COUNTRY_CODES, [user => user.name], ['asc'])

const CustomFilters = ({ translate, ...props }) => (
  <Filter {...props}>
    <TextInput
      label={translate('resources.users.fields.nickname')}
      source='nickname||$contL'
      resettable
      autoComplete='off'
    />
    <TextInput
      label={translate('resources.users.fields.email')}
      source='email||$contL'
      resettable
      autoComplete='off'
    />
    <TextInput
      label={translate('resources.users.fields.invitationCode')}
      source='invitationCode||$contL'
      resettable
      autoComplete='off'
    />
    <BooleanInput
      label={translate('resources.users.fields.publisher')}
      source='publisher||$eq'
      resettable
      defaultValue={true}
    />
    <BooleanInput
      label={translate('resources.users.fields.agency')}
      source='agency||$eq'
      resettable
      defaultValue={true}
    />
    <BooleanInput
      label={translate('resources.users.fields.banned')}
      source='banned||$eq'
      resettable
      defaultValue={true}
    />
    <DateInput
      label={translate('resources.users.fields.createdAt')}
      source='createdAt||$eqdate'
      autoComplete='off'
      resettable
    />
    <DateInput
      label={translate('resources.users.fields.updatedAt')}
      source='updatedAt||$eqdate'
      autoComplete='off'
      resettable
    />
    <QuickFilter
      label={translate('resources.users.fields.noAvailableVideos')}
      source='availableVideos||$eq'
      defaultValue={0}
      resettable
    />
    <SelectInput
      label={translate('resources.users.fields.billingInfo.residenceCountry')}
      source='billingInfo.residenceCountry||$eq'
      choices={formatCountryCodes}
      defaultValue='ES'
    />
    <SelectInput
      label={translate('resources.users.fields.billingInfo.companyCountry')}
      source='billingInfo.companyCountry||$eq'
      choices={formatCountryCodes}
      defaultValue='ES'
    />
    <TextInput
      label={`${translate('resources.users.fields.referredBy')} (${translate('resources.users.fields.nickname')})`}
      source='referredBy.nickname||$contL'
      resettable
      autoComplete='off'
    />
    <BooleanInput
      label={translate('resources.users.fields.featured')}
      source='featured||$eq'
      resettable
      defaultValue={true}
    />
    <BooleanInput
      label={translate('resources.users.fields.billingInfo.checked')}
      source='billingInfo.checked||$eq'
      resettable
      defaultValue={true}
    />
  </Filter>
)

CustomFilters.propTypes = {
  translate: PropTypes.func,
}

export default CustomFilters
