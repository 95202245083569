import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useCallback } from 'react'
import { SaveButton, useUpdate, useRedirect, useNotify } from 'react-admin'
import * as API from '../../../../api'

const SaveWithNoteButton = props => {
  const [approve, { loading }] = useUpdate('users', props.record.id, { isApproved: true }, props.record)
  const redirectTo = useRedirect()
  const notify = useNotify()
  const { basePath } = props
  const handleSaveData = useCallback(
    (values, redirect) => {
      const beneficiaryId = _.get(values, 'contributions[0].beneficiaryId', '')
      const percentage = _.get(values, 'contributions[0].percentage', 0)

      const newValues = _.omit(values, [
        'contributions',
        'isApproved',
        'creatorId',
        'language',
        'id',
        'username',
        'email',
        'birthdate',
        'location',
        'referralCode',
        'stripeId',
        'availableVideos',
        'role',
        'createdAt',
        'updatedAt',
        'followersCount',
        'followingCount',
        'currentlyFollowing',
        'average_note',
        'billingInfo.createdAt',
        'billingInfo.updatedAt',
        'invitedBy',
        'usersInvitedCount',
        'platformEndpointArn',
        'contributions',
        'termsAndConditionsAcceptedAt',
      ])

      if (newValues?.billingInfo?.photoDocument) {
        newValues.billingInfo.photoDocument = { id: newValues?.billingInfo?.photoDocument?.id || null }
      }

      if (newValues?.billingInfo?.holdingPhotoDocument) {
        newValues.billingInfo.holdingPhotoDocument = {
          id: newValues?.billingInfo?.holdingPhotoDocument?.id || null,
        }
      }

      if (newValues?.billingInfo?.bankCertificateDocument) {
        newValues.billingInfo.bankCertificateDocument = {
          id: newValues?.billingInfo?.bankCertificateDocument?.id || null,
        }
      }

      if (newValues?.billingInfo?.companyCertificateDocument) {
        newValues.billingInfo.companyCertificateDocument = {
          id: newValues?.billingInfo?.companyCertificateDocument?.id || null,
        }
      }

      if (!newValues?.referredBy?.id) {
        newValues.referredBy = null
      }

      approve(
        { payload: { data: newValues } },
        {
          onSuccess: async ({ data: newRecord }) => {
            notify('ra.notification.updated', 'info', { smart_count: 1 })

            if (beneficiaryId && percentage > 0) {
              try {
                const userId = values.id
                const beneficiariesData = [{ beneficiaryId, percentage }]
                const data = { data: beneficiariesData }
                await API.updateUserContributions({ userId, data })
              } catch {
                notify('resources.users.fields.contributionsError', 'warning', { smart_count: 1 })
              }
            }

            redirectTo(redirect, basePath, newRecord.id, newRecord)
          },
          onFailure: error => notify(error?.message, 'warning'),
        }
      )
    },
    [approve, notify, redirectTo, basePath]
  )

  return <SaveButton disabled={loading} {...props} saving={loading} onSave={handleSaveData} />
}

SaveWithNoteButton.propTypes = {
  record: PropTypes.object,
  basePath: PropTypes.string,
}

export default SaveWithNoteButton
