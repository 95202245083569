import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const RegularButton = ({ disabled, label, variant, color, type, onClick, isFetching }) => {
  return (
    <div style={styles.wrapper}>
      <Button
        style={styles.btn}
        disabled={isFetching || disabled}
        variant={variant}
        type={type}
        color={color}
        onClick={onClick}
      >
        {label}
      </Button>
      {isFetching && <CircularProgress size={24} style={styles.buttonProgress} />}
    </div>
  )
}

RegularButton.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
}

RegularButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
  onClick: () => {},
  disabled: false,
}

const enharce = withStyles(styles)

export default enharce(RegularButton)
