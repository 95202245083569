import React from 'react'
import { Show, SimpleShowLayout } from 'react-admin'
import { ReadFields } from '../commons'

const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      {ReadFields()}
    </SimpleShowLayout>
  </Show>
)

export default show
