import React, { Fragment } from 'react'
import { BulkDeleteWithConfirmButton, withTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const BulkActionsButtons = ({ translate, ...props }) => {
  return (
    <Fragment>
      <BulkDeleteWithConfirmButton
        confirmTitle={translate('delete')}
        confirmContent={translate('confirmDeleteContent')}
        {...props}
      />
    </Fragment>
  )
}

BulkActionsButtons.propTypes = {
  translate: PropTypes.func,
}

export default withTranslate(BulkActionsButtons)
