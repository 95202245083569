export default {
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '30px',
    paddingBottom: '15px',
  },
  spinnerWrapper: {
    textAlign: 'center',
    padding: '30px 15px',
  },
}
