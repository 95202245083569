export default {
  btnWrapper: {
    marginBottom: '15px',
  },
  title: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  validatePassword: {
    fontSize: '12px',
    marginBottom: '15px',
  },
  back: {
    cursor: 'pointer',
  },
}
