import React from 'react'
import { Edit, SimpleForm } from 'react-admin'
import { EditFields } from '../commons'
import EditToolbar from '../../../molecules/editToolbar'

const edit = props => (
  <Edit {...props} undoable={false} >
    <SimpleForm redirect='show' toolbar={<EditToolbar deleteEnabled={false} />}>
      {EditFields()}
    </SimpleForm>
  </Edit>
)

export default edit
