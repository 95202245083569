import React, { Component, createRef } from 'react'
import styles from './styles'
import { RegularButton } from '../../atoms'
import { withTranslate, showNotification, Notification } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  TextField as MaterialTextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
} from '@material-ui/core'
import * as API from '../../../api'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import { connect } from 'react-redux'
import _ from 'lodash'

const LIMIT = 5
const PAGE = 1
const TOTAL = 0
const SOURCE = 'beneficiary'
const ID_LABEL_FILE = 'beneficiaryId'

class BeneficiaryDocuments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentsData: [],
      documentsLimit: LIMIT,
      documentsPage: PAGE,
      documentsTotal: TOTAL,
      isFetchingDocuments: false,
      description: '',
      file: null,
    }
    this.fileRef = createRef()
  }

  componentDidMount() {
    let { documentsData, documentsLimit, documentsPage, documentsTotal } = this.state
    this.getDocuments(documentsData, documentsTotal, documentsPage, documentsLimit)
  }

  getDocuments = async (documentsData, documentsTotal, documentsPage, documentsLimit) => {
    try {
      const offset = documentsLimit * (documentsPage - 1)
      this.setState({ isFetchingDocuments: true })
      const { id } = this.props
      const response = await API.getDocuments({
        source: SOURCE,
        id,
        limit: documentsLimit,
        page: documentsPage,
        offset,
      })
      documentsData = _.get(response, 'data.data', '')
      documentsTotal = _.get(response, 'data.total', '')
      documentsPage = _.get(response, 'data.page', '')

      this.setState({ documentsData, documentsTotal, documentsPage, isFetchingDocuments: false })
    } catch {
      this.setState({ isFetchingDocuments: false })
    }
  }

  handleChangeDocumentPage = (event, documentsPage) => {
    let { documentsData, documentsTotal, documentsLimit } = this.state
    this.getDocuments(documentsData, documentsTotal, documentsPage, documentsLimit)
  }

  handleClickDownloadDoc = async docId => {
    const { translate } = this.props

    try {
      const response = await API.getDocumentUrl(docId)
      const url = _.get(response, 'data.url', '')

      if (url) {
        window.location = url
      }
    } catch {
      showNotification(translate('generalError'), 'warning')
    }
  }

  handleUploadFile = async () => {
    const { id, translate } = this.props
    const { file, description } = this.state
    const { showNotification } = this.props

    try {
      this.setState({ isFetchingDocuments: true })
      await API.postDocument({ idLabel: ID_LABEL_FILE, id, file, description })
      const response = await API.getDocuments({
        source: SOURCE,
        id,
        limit: LIMIT,
        page: PAGE,
        total: TOTAL,
      })
      const documentsData = _.get(response, 'data.data', '')
      const documentsTotal = _.get(response, 'data.total', '')
      const documentsPage = _.get(response, 'data.page', '')

      this.fileRef.current.value = null

      this.setState({
        description: '',
        file: null,
        documentsData,
        documentsTotal,
        documentsPage,
        isFetchingDocuments: false,
      })
    } catch {
      showNotification(translate('generalError'), 'warning')
    } finally {
      this.setState({ isFetchingDocuments: false })
    }
  }

  handleChangeFile = e => {
    const file = e.target.files[0]
    this.setState({ file })
  }

  render() {
    const {
      isFetchingDocuments,
      documentsLimit,
      documentsTotal,
      documentsPage,
      documentsData,
      description,
      file,
    } = this.state
    const { translate } = this.props
    const totalTransactionPages = Math.ceil(documentsTotal / documentsLimit)

    const formatDocuments = _.map(documentsData, (document, key) => {
      const docId = _.get(document, 'id', '')
      const description = _.get(document, 'description', '')
      const originalName = _.get(document, 'originalFileName', '')
      const createdAt = document.createdAt ? moment(document.createdAt).format('DD/MM/YYYY HH:mm') : ''

      return (
        <TableRow key={key}>
          <TableCell>{description}</TableCell>
          <TableCell>{originalName}</TableCell>
          <TableCell>{createdAt}</TableCell>
          <TableCell>
            <DownloadIcon onClick={() => this.handleClickDownloadDoc(docId)} style={styles.downloadIcon} />
          </TableCell>
        </TableRow>
      )
    })

    return (
      <div>
        <div style={styles.uploadWrapper}>
          <div style={styles.descriptionWrapper}>
            <MaterialTextField
              value={description}
              onChange={e => this.setState({ description: e.target.value })}
              fullWidth
              type='text'
              placeholder={translate('resources.beneficiaries.fields.docDescription')}
            />
          </div>

          <div style={styles.fileWrapper}>
            <input ref={this.fileRef} style={styles.fileInput} onChange={this.handleChangeFile} fullWidth type='file' />
            <Typography variant='body2' component='p'>
              *{translate('resources.beneficiaries.fields.maxSize')}
            </Typography>
          </div>
          <div style={styles.btnUpload}>
            <RegularButton
              onClick={this.handleUploadFile}
              disabled={!description || !file || isFetchingDocuments}
              label={translate('resources.beneficiaries.fields.uploadFile')}
            />
          </div>
        </div>
        {isFetchingDocuments ? (
          <div style={styles.spinnerWrapper}>
            <CircularProgress />
          </div>
        ) : documentsData?.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{translate('resources.beneficiaries.fields.docDescription')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.beneficiaries.fields.fileName')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.beneficiaries.fields.fileDate')}</strong>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{formatDocuments}</TableBody>
              </Table>
            </TableContainer>

            <Pagination
              style={styles.pagination}
              onChange={this.handleChangeDocumentPage}
              page={documentsPage}
              count={totalTransactionPages}
              color='primary'
            />
          </>
        ) : (
          <Typography variant='body2' component='p'>
            {translate('resources.beneficiaries.fields.noResults')}
          </Typography>
        )}
        <Notification autoHideDuration={5000} />
      </div>
    )
  }
}

BeneficiaryDocuments.propTypes = {
  translate: PropTypes.func,
  id: PropTypes.string,
  showNotification: PropTypes.func,
}

const enhanced = compose(
  connect(null, {
    showNotification,
  }),
  withTranslate
)

export default enhanced(BeneficiaryDocuments)
