export const AWS_DOMAIN = process.env.REACT_APP_AWS_DOMAIN
export const AWS_REDIRECT_SIGNIN = process.env.REACT_APP_AWS_REDIRECT_SIGNIN
export const AWS_REDIRECT_SIGNOUT = process.env.REACT_APP_AWS_REDIRECT_SIGNOUT
export const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET
export const AWS_REGION = process.env.REACT_APP_AWS_REGION
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID
export const AWS_STORAGE_REGION = process.env.REACT_APP_AWS_STORAGE_REGION
export const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID
export const AWS_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID

export const NATURAL_PERSON_TYPE = 'natural'

export const LEGAL_PERSON_TYPE = 'legal'

export const PASSPORT = 'passport'

export const NATIONAL_ID = 'national_id'

export const NATIONAL_SCOPE = 'national'

export const INTERNATIONAL_SCOPE = 'international'

export const ROLES_CHOICES = [
  { id: 'superadmin', name: 'superadmin' },
  { id: 'administrator', name: 'administrator' },
  { id: 'marketing', name: 'marketing' },
]

export const AVAILABLE_CONTEXT_OPTIONS = [
  { id: 'billing', name: 'resources.beneficiaries.fields.billing' },
  { id: 'purchase', name: 'resources.beneficiaries.fields.purchase' },
]

export const SCOPE_OPTIONS = [
  { id: NATIONAL_SCOPE, name: 'resources.beneficiaries.fields.nationalScope' },
  { id: INTERNATIONAL_SCOPE, name: 'resources.beneficiaries.fields.internationalScope' },
]

export const COUNTRY_CODES = [
  { id: 'AF', name: 'Afganistán' },
  { id: 'AL', name: 'Albania' },
  { id: 'DZ', name: 'Argelia' },
  { id: 'AS', name: 'Samoa Americana' },
  { id: 'AD', name: 'Andorra' },
  { id: 'AO', name: 'Angola' },
  { id: 'AI', name: 'Anguila' },
  { id: 'AQ', name: 'Antártida' },
  { id: 'AG', name: 'Antigua y Barbuda' },
  { id: 'AR', name: 'Argentina' },
  { id: 'AM', name: 'Armenia' },
  { id: 'AW', name: 'Aruba' },
  { id: 'AU', name: 'Australia' },
  { id: 'AT', name: 'Austria' },
  { id: 'AZ', name: 'Azerbaiyán' },
  { id: 'BS', name: 'Bahamas' },
  { id: 'BH', name: 'Bahrein' },
  { id: 'BD', name: 'Bangladesh' },
  { id: 'BB', name: 'Barbados' },
  { id: 'BY', name: 'Belarús' },
  { id: 'BE', name: 'Bélgica' },
  { id: 'BZ', name: 'Belice' },
  { id: 'BJ', name: 'Benin' },
  { id: 'BM', name: 'Bermudas' },
  { id: 'BT', name: 'Bhután' },
  { id: 'BO', name: 'Bolivia' },
  { id: 'BA', name: 'Bosnia y Herzegovina' },
  { id: 'BW', name: 'Botswana' },
  { id: 'BV', name: 'Isla Bouvet' },
  { id: 'BR', name: 'Brasil' },
  { id: 'IO', name: 'Territorio Británico del Océano Índico' },
  { id: 'BN', name: 'Brunei Darussalam' },
  { id: 'BG', name: 'Bulgaria' },
  { id: 'BF', name: 'Burkina Faso' },
  { id: 'BI', name: 'Burundi' },
  { id: 'KH', name: 'Camboya' },
  { id: 'CM', name: 'Camerún' },
  { id: 'CA', name: 'Canadá' },
  { id: 'CV', name: 'Cabo Verde' },
  { id: 'KY', name: 'Islas Caimán' },
  { id: 'CF', name: 'República Centroafricana' },
  { id: 'TD', name: 'Chad' },
  { id: 'CL', name: 'Chile' },
  { id: 'CN', name: 'China' },
  { id: 'CX', name: 'Isla de Navidad' },
  { id: 'CC', name: 'Islas Cocos (Keeling)' },
  { id: 'CO', name: 'Colombia' },
  { id: 'KM', name: 'Comoras' },
  { id: 'CG', name: 'Congo' },
  { id: 'CD', name: 'Congo (República Democrática del)' },
  { id: 'CK', name: 'Islas Cook' },
  { id: 'CR', name: 'Costa Rica' },
  { id: 'CI', name: 'Costa de Marfil' },
  { id: 'HR', name: 'Croacia' },
  { id: 'CU', name: 'Cuba' },
  { id: 'CY', name: 'Chipre' },
  { id: 'CZ', name: 'República Checa' },
  { id: 'DK', name: 'Dinamarca' },
  { id: 'DJ', name: 'Djibouti' },
  { id: 'DM', name: 'Dominica' },
  { id: 'DO', name: 'República Dominicana' },
  { id: 'EC', name: 'Ecuador' },
  { id: 'EG', name: 'Egipto' },
  { id: 'SV', name: 'El Salvador' },
  { id: 'GQ', name: 'Guinea Ecuatorial' },
  { id: 'ER', name: 'Eritrea' },
  { id: 'EE', name: 'Estonia' },
  { id: 'ET', name: 'Etiopía' },
  { id: 'FK', name: 'Islas Malvinas' },
  { id: 'FO', name: 'Islas Feroe' },
  { id: 'FJ', name: 'Fiji' },
  { id: 'FI', name: 'Finlandia' },
  { id: 'FR', name: 'Francia' },
  { id: 'GF', name: 'Guayana Francesa' },
  { id: 'PF', name: 'Polinesia Francesa' },
  { id: 'TF', name: 'Tierras Australes Francesas' },
  { id: 'GA', name: 'Gabón' },
  { id: 'GM', name: 'Gambia' },
  { id: 'GE', name: 'Georgia' },
  { id: 'DE', name: 'Alemania' },
  { id: 'GH', name: 'Ghana' },
  { id: 'GI', name: 'Gibraltar' },
  { id: 'GR', name: 'Grecia' },
  { id: 'GL', name: 'Groenlandia' },
  { id: 'GD', name: 'Granada' },
  { id: 'GP', name: 'Guadeloupe' },
  { id: 'GU', name: 'Guam' },
  { id: 'GT', name: 'Guatemala' },
  { id: 'GN', name: 'Guinea' },
  { id: 'GW', name: 'Guinea Bissau' },
  { id: 'GY', name: 'Guyana' },
  { id: 'HT', name: 'Haití' },
  { id: 'HM', name: 'Heard e Islas McDonald' },
  { id: 'VA', name: 'Santa Sede' },
  { id: 'HN', name: 'Honduras' },
  { id: 'HK', name: 'Hong Kong' },
  { id: 'HU', name: 'Hungría' },
  { id: 'IS', name: 'Islandia' },
  { id: 'IN', name: 'India' },
  { id: 'ID', name: 'Indonesia' },
  { id: 'IR', name: 'Irán (República Islámica de)' },
  { id: 'IQ', name: 'Iraq' },
  { id: 'IE', name: 'Irlanda' },
  { id: 'IL', name: 'Israel' },
  { id: 'IT', name: 'Italia' },
  { id: 'JM', name: 'Jamaica' },
  { id: 'JP', name: 'Japón' },
  { id: 'JO', name: 'Jordania' },
  { id: 'KZ', name: 'Kazajstán' },
  { id: 'KE', name: 'Kenya' },
  { id: 'KI', name: 'Kiribati' },
  { id: 'KP', name: 'República Popular Democrática de Corea' },
  { id: 'KR', name: 'República de Corea' },
  { id: 'KW', name: 'Kuwait' },
  { id: 'KG', name: 'Kirguistán' },
  { id: 'LA', name: 'República Democrática Popular de Lao' },
  { id: 'LV', name: 'Letonia' },
  { id: 'LB', name: 'Líbano' },
  { id: 'LS', name: 'Lesotho' },
  { id: 'LR', name: 'Liberia' },
  { id: 'LY', name: 'Libia' },
  { id: 'LI', name: 'Liechtenstein' },
  { id: 'LT', name: 'Lituania' },
  { id: 'LU', name: 'Luxemburgo' },
  { id: 'MO', name: 'Macao' },
  { id: 'MK', name: 'Macedonia del Norte' },
  { id: 'MG', name: 'Madagascar' },
  { id: 'MW', name: 'Malawi' },
  { id: 'MY', name: 'Malasia' },
  { id: 'MV', name: 'Maldivas' },
  { id: 'ML', name: 'Malí' },
  { id: 'MT', name: 'Malta' },
  { id: 'MH', name: 'Islas Marshall' },
  { id: 'MQ', name: 'Martinique' },
  { id: 'MR', name: 'Mauritania' },
  { id: 'MU', name: 'Mauricio' },
  { id: 'YT', name: 'Mayotte' },
  { id: 'MX', name: 'México' },
  { id: 'FM', name: 'Micronesia' },
  { id: 'MD', name: 'Moldavia' },
  { id: 'MC', name: 'Mónaco' },
  { id: 'MN', name: 'Mongolia' },
  { id: 'MS', name: 'Montserrat' },
  { id: 'MA', name: 'Marruecos' },
  { id: 'MZ', name: 'Mozambique' },
  { id: 'MM', name: 'Myanmar' },
  { id: 'NA', name: 'Namibia' },
  { id: 'NR', name: 'Nauru' },
  { id: 'NP', name: 'Nepal' },
  { id: 'NL', name: 'Países Bajos' },
  { id: 'NC', name: 'Nueva Caledonia' },
  { id: 'NZ', name: 'Nueva Zelanda' },
  { id: 'NI', name: 'Nicaragua' },
  { id: 'NE', name: 'Níger' },
  { id: 'NG', name: 'Nigeria' },
  { id: 'NU', name: 'Niue' },
  { id: 'NF', name: 'Isla Norfolk' },
  { id: 'MP', name: 'Isla Marianas del Norte' },
  { id: 'NO', name: 'Noruega' },
  { id: 'OM', name: 'Omán' },
  { id: 'PK', name: 'Pakistán' },
  { id: 'PW', name: 'Palau' },
  { id: 'PS', name: 'Palestina' },
  { id: 'PA', name: 'Panamá' },
  { id: 'PG', name: 'Papua Nueva Guinea' },
  { id: 'PY', name: 'Paraguay' },
  { id: 'PE', name: 'Perú' },
  { id: 'PH', name: 'Filipinas' },
  { id: 'PN', name: 'Pitcairn' },
  { id: 'PL', name: 'Polonia' },
  { id: 'PT', name: 'Portugal' },
  { id: 'PR', name: 'Puerto Rico' },
  { id: 'QA', name: 'Qatar' },
  { id: 'RE', name: 'Reunión' },
  { id: 'RO', name: 'Rumania' },
  { id: 'RU', name: 'Rusia' },
  { id: 'RW', name: 'Rwanda' },
  { id: 'SH', name: 'Santa Helena, Ascensión y Tristán de Acuña' },
  { id: 'KN', name: 'Saint Kitts y Nevis' },
  { id: 'LC', name: 'Santa Lucía' },
  { id: 'PM', name: 'San Pedro y Miquelón' },
  { id: 'VC', name: 'San Vicente y las Granadinas' },
  { id: 'WS', name: 'Samoa' },
  { id: 'SM', name: 'San Marino' },
  { id: 'ST', name: 'Santo Tomé y Príncipe' },
  { id: 'SA', name: 'Arabia Saudita' },
  { id: 'SN', name: 'Senegal' },
  { id: 'SC', name: 'Seychelles' },
  { id: 'SL', name: 'Sierra Leona' },
  { id: 'SG', name: 'Singapur' },
  { id: 'SK', name: 'Eslovaquia' },
  { id: 'SI', name: 'Eslovenia' },
  { id: 'SB', name: 'Islas Salomón' },
  { id: 'SO', name: 'Somalia' },
  { id: 'ZA', name: 'Sudáfrica' },
  { id: 'GS', name: 'Georgia del Sur y las Islas Sandwich del Sur' },
  { id: 'ES', name: 'España' },
  { id: 'LK', name: 'Sri Lanka' },
  { id: 'SD', name: 'Sudan' },
  { id: 'SR', name: 'Suriname' },
  { id: 'SJ', name: 'Svalbard y Jan Mayen' },
  { id: 'SZ', name: 'Swazilandia' },
  { id: 'SE', name: 'Suecia' },
  { id: 'CH', name: 'Suiza' },
  { id: 'SY', name: 'República Árabe Siria' },
  { id: 'TW', name: 'Taiwán' },
  { id: 'TJ', name: 'Tayikistán' },
  { id: 'TZ', name: 'Tanzania' },
  { id: 'TH', name: 'Tailandia' },
  { id: 'TL', name: 'Timor-Leste' },
  { id: 'TG', name: 'Togo' },
  { id: 'TK', name: 'Tokelau' },
  { id: 'TO', name: 'Tonga' },
  { id: 'TT', name: 'Trinidad y Tobago' },
  { id: 'TN', name: 'Túnez' },
  { id: 'TR', name: 'Turquía' },
  { id: 'TM', name: 'Turkmenistán' },
  { id: 'TC', name: 'Islas Turcas y Caicos' },
  { id: 'TV', name: 'Tuvalu' },
  { id: 'UG', name: 'Uganda' },
  { id: 'UA', name: 'Ucrania' },
  { id: 'AE', name: 'Emiratos Árabes Unidos' },
  { id: 'GB', name: 'Reino Unido' },
  { id: 'US', name: 'Estados Unidos' },
  { id: 'UM', name: 'Islas Ultramarinas Menores de los Estados Unidos' },
  { id: 'UY', name: 'Uruguay' },
  { id: 'UZ', name: 'Uzbekistán' },
  { id: 'VU', name: 'Vanuatu' },
  { id: 'VE', name: 'Venezuela' },
  { id: 'VN', name: 'Vietnam' },
  { id: 'VG', name: 'Islas Vírgenes británicas' },
  { id: 'VI', name: 'Islas Vírgenes de los Estados Unidos' },
  { id: 'WF', name: 'Wallis y Futuna' },
  { id: 'EH', name: 'Sahara Occidental' },
  { id: 'YE', name: 'Yemen' },
  { id: 'ZM', name: 'Zambia' },
  { id: 'ZW', name: 'Zimbabwe' },
  { id: 'AX', name: 'Islas Åland' },
  { id: 'BQ', name: 'Bonaire, San Eustaquio y Saba' },
  { id: 'CW', name: 'Curaçao' },
  { id: 'GG', name: 'Guernsey' },
  { id: 'IM', name: 'Isla de Man' },
  { id: 'JE', name: 'Jersey' },
  { id: 'ME', name: 'Montenegro' },
  { id: 'BL', name: 'Saint Barthélemy' },
  { id: 'MF', name: 'Saint Martin (francesa)' },
  { id: 'RS', name: 'Serbia' },
  { id: 'SX', name: 'Sint Maarten (neerlandesa)' },
  { id: 'SS', name: 'Sudán del Sur' },
  { id: 'XK', name: 'Kosovo' },
]

export const CREATOR_TYPE_OPTIONS = [
  { id: 'default', name: 'resources.users.fields.publisherTypeOptions.default' },
  { id: 'promise', name: 'resources.users.fields.publisherTypeOptions.promise' },
]

export const PUBLISHER_STATUS = [
  { id: 'allowed', name: 'resources.users.fields.publisherStatusOptions.allowed' },
  { id: 'notAllowed', name: 'resources.users.fields.publisherStatusOptions.notAllowed' },
]
