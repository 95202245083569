import React from 'react'
import { Create, SimpleForm, TextInput, required, BooleanInput, NumberInput, number, minValue } from 'react-admin'

const create = props => {
  const validateInvitationCount = [number(), minValue(1)]

  return (
    <Create {...props}>
      <SimpleForm redirect='list'>
        <TextInput source='name' validate={required()} />
        <TextInput source='nickname' validate={required()} />
        <TextInput source='email' validate={required()} parse={value => value.toLowerCase()} />
        <BooleanInput source='publisher' defaultValue={false} />
        <BooleanInput source='freeVideos' defaultValue={false} />
        <BooleanInput source='agency' defaultValue={false} />
        <TextInput source='invitationCode' />
        <NumberInput source='invitationCount' validate={validateInvitationCount} />
      </SimpleForm>
    </Create>
  )
}

export default create
