import React, { Component } from 'react'
import { withTranslate } from 'react-admin'
import _ from 'lodash'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import * as API from '../../../../api'
import { Typography, InputLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import styles from './styles'
import moment from 'moment'
import { priceFormatter } from '../../../../utils'
import { ClearTwoTone, CheckTwoTone } from '@material-ui/icons'

class show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      beneficiaryEarnings: {},
      isFetching: false,
    }
  }

  async componentDidMount() {
    this.setState({ isFetching: true })
    let filter = localStorage.getItem('beneficiaryFilter')
    filter = filter && JSON.parse(filter)
    const { id } = this.props
    const response = await API.getBeneficiaryEarnings(id, filter)
    const beneficiaryEarnings = _.get(response, 'data', {})
    this.setState({ beneficiaryEarnings, isFetching: false })
  }

  render() {
    const { translate } = this.props
    const { beneficiaryEarnings, isFetching } = this.state
    const total = _.get(beneficiaryEarnings, 'total')
    const publisherCount = _.get(beneficiaryEarnings, 'publisherCount')
    const userCount = _.get(beneficiaryEarnings, 'userCount')
    const items = _.get(beneficiaryEarnings, 'items', [])
    const userDonation = _.sumBy(items, n => n.type === 'user_donation' && n.amount)
    const publisherDonation = _.sumBy(items, n => n.type === 'publisher_donation' && n.amount)
    const paid = _.get(beneficiaryEarnings, 'paid')
    const beneficiary = _.get(beneficiaryEarnings, 'nameEs', '')
    const periodStart = beneficiaryEarnings?.periodStart
      ? moment(beneficiaryEarnings.periodStart).format('D/M/YYYY')
      : null
    const periodEnd = beneficiaryEarnings?.periodEnd ? moment(beneficiaryEarnings.periodEnd).format('D/M/YYYY') : null

    if (isFetching) return null

    return (
      <div style={styles.root}>
        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.total')}</InputLabel>
          <Typography variant='body2' component='span'>
            {total && priceFormatter(total / 100)}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.publisherCount')}</InputLabel>
          <Typography variant='body2' component='span'>
            {publisherCount}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.publisherDonation')}</InputLabel>
          <Typography variant='body2' component='span'>
            {publisherDonation && priceFormatter(publisherDonation / 100)}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.userCount')}</InputLabel>
          <Typography variant='body2' component='span'>
            {userCount}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.userDonation')}</InputLabel>
          <Typography variant='body2' component='span'>
            {userDonation && priceFormatter(userDonation / 100)}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.beneficiarie')}</InputLabel>
          <Typography variant='body2' component='span'>
            {beneficiary}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.periodStart')}</InputLabel>
          <Typography variant='body2' component='span'>
            {periodStart}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.periodEnd')}</InputLabel>
          <Typography variant='body2' component='span'>
            {periodEnd}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.paid')}</InputLabel>
          <Typography variant='body2' component='span'>
            {paid ? <CheckTwoTone /> : <ClearTwoTone />}
          </Typography>
        </div>

        <div style={styles.fieldWrapper}>
          <InputLabel shrink={true}>{translate('resources.beneficiary-earnings.fields.contributions')}</InputLabel>
          <div style={styles.tableWrapper}>
            <Table aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>{translate('resources.beneficiary-earnings.fields.publisher')}</TableCell>
                  <TableCell>{translate('resources.beneficiary-earnings.fields.amount')}</TableCell>
                  <TableCell>{translate('resources.beneficiary-earnings.fields.type')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(items, (item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.publisher?.nickname}</TableCell>
                    <TableCell>{item.amount && priceFormatter(item.amount / 100)}</TableCell>
                    <TableCell>
                      {item.type ? translate(`resources.beneficiary-earnings.fields.${item.type}`) : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

show.propTypes = {
  translate: PropTypes.func,
  id: PropTypes.string,
}

const enhanced = compose(withTranslate)

export default enhanced(show)
