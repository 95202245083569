import React, { useEffect } from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useEditController,
  useTranslate,
  NumberInput,
  FormDataConsumer,
  useNotify
} from 'react-admin';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { getMaximumNumberOfPurchases, purcharseVideoViews } from '../../../../api';

const useStyles = makeStyles({
  flexingDiv: {
    display: 'flex',
    width: '100%',
    height: '45px',
    alignItems: 'baseline',
  },
  customTextInput: {
    marginTop: '8px',
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
    },
  }
});

const CustomEdit = props => {
  const { record } = useEditController(props);
  const translate = useTranslate();
  const materialStyles = useStyles();
  const [purcharseValue, setPurcharseValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [maximumNumberOfPurchases, setMaximumNumberOfPurchases] = React.useState(0);
  const notify = useNotify()

  useEffect(() => {
    const fetchData = async () => {
      if (record && record?.id) {
        let maxNum = await getMaximumNumberOfPurchases(record.id);
        setMaximumNumberOfPurchases(maxNum);
      }
    };

    fetchData();
  }, [record]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (!inputValue) {
      setPurcharseValue(0);
      return;
    }

    const numberValue = parseInt(inputValue);

    if (isNaN(numberValue)) {
      return;
    }

    if (numberValue <= 0) {
      setPurcharseValue(0);
      return;
    }

    if (numberValue > maximumNumberOfPurchases) {
      setPurcharseValue(maximumNumberOfPurchases);
      return;
    }

    setPurcharseValue(numberValue);
  };

  const handleClickOpen = () => {
    if (!(purcharseValue > 0)){
      return;
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async () => {
    try {
      await purcharseVideoViews(record?.id, purcharseValue);
      window.location.reload();
    } catch (error) {
      notify('generalError', 'warning');
    }

    setOpen(false);
  };

  return (
    <>
      <Edit {...props} undoable={false}>
        <SimpleForm redirect='show'>
          <TextInput source='title'/>
          <ReferenceInput
            allowEmpty
            emptyText={translate('resources.videos.fields.emptyCategory')}
            perPage={100}
            label={translate('resources.videos.fields.category')}
            source='category.id'
            reference='categories'
          >
            <SelectInput optionText='code' />
          </ReferenceInput>
          <BooleanInput source='highlighted' />
          {!record?.free && <BooleanInput label={translate('resources.videos.fields.free')} source='free' />}

          <FormDataConsumer>
            {() => (
              <>
                <Typography variant="body1">{`${translate('resources.videos.fields.maximumNumberOfPurchases')} ${maximumNumberOfPurchases}`}</Typography>
                <div className={materialStyles.flexingDiv}>
                  <NumberInput
                    label={translate('resources.videos.fields.totalPurchases')}
                    id="filled-disabled"
                    variant="outlined"
                    disabled
                    source='purchasesCount'
                  />
                  <TextField
                    className={materialStyles.customTextInput}
                    label={translate('resources.videos.fields.addPurchases')}
                    variant="outlined"
                    type="number"
                    value={purcharseValue}
                    onChange={handleInputChange}
                  />
                  <Button variant="outlined" onClick={handleClickOpen} className={materialStyles.customTextInput}>
                    {translate('resources.videos.fields.purchaseButton')} 
                  </Button>
                </div>
              </>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${translate('resources.videos.fields.popUpTitle')} ${purcharseValue} ${translate('resources.videos.fields.purchases')}.`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('resources.videos.fields.popUpDesc')} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{translate('resources.videos.fields.popUpCancel')}</Button>
          <Button onClick={handleAccept}>{translate('resources.videos.fields.popUpAccept')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const edit = props => <CustomEdit {...props} />

export default edit
