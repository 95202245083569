export default {
  root: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'white',
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundColor: '#dddddd',
    height: '1px',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  formWrapper: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '15px',
    minWidth: '300px',
    maxWidth: '300px',
    marginTop: '6em',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}
