import _ from 'lodash'
import { Auth } from 'aws-amplify'
import { isAdminUser, isSuperAdminUser, isMarketingUser } from '../utils'
import { roles } from '../config/api'
import jwtDecode from 'jwt-decode'

const login = {
  login: async ({ username, password }) => {
    try {
      const user = await Auth.signIn({ username, password })
      const token = _.get(user, 'signInUserSession.accessToken.jwtToken', '')

      if (token) {
        const decoded = jwtDecode(token)
        const permissions = _.get(decoded, 'cognito:groups')
        const isRoleAdmin = isAdminUser(permissions)
        const isRoleSuperAdmin = isSuperAdminUser(permissions)
        const isRoleMarketing = isMarketingUser(permissions)

        if (isRoleAdmin || isRoleSuperAdmin || isRoleMarketing) {
          localStorage.setItem('token', token)
          return Promise.resolve()
        }

        return Promise.reject()
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED' || user.challengeName === 'RESET_REQUIRED') {
        window.location = '/?#/completePassword'
        return Promise.reject('Error, es necesario restaurar la contraseña')
      }
      return Promise.reject()
    } catch {
      return Promise.reject()
    }
  },
  logout: () => {
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => {
    try {
      const token = localStorage.getItem('token')
      const decoded = jwtDecode(token)
      const permissions = _.get(decoded, 'cognito:groups')
      return Promise.resolve(permissions)
    } catch {
      return Promise.resolve(roles.ROLE_GUEST)
    }
  },
}

export default login
