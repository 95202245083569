import React from 'react'
import { Edit, SimpleForm, TextInput, required } from 'react-admin'

const edit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect='show'>
      <TextInput source='code' validate={required()} />
      <TextInput source='nameEs' validate={required()} />
      <TextInput source='nameEn' validate={required()} />
    </SimpleForm>
  </Edit>
)

export default edit
