import {
  AWS_DOMAIN,
  AWS_REDIRECT_SIGNIN,
  AWS_REDIRECT_SIGNOUT,
  AWS_BUCKET,
  AWS_REGION,
  AWS_STORAGE_REGION,
  AWS_IDENTITY_POOL_ID,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEB_CLIENT_ID,
} from './constants'

export const AUTH_CONFIG = {
  domain: AWS_DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: AWS_REDIRECT_SIGNIN,
  redirectSignOut: AWS_REDIRECT_SIGNOUT,
  responseType: 'code',
}

const STORAGE_CONFIG = {
  bucket: AWS_BUCKET,
  region: AWS_REGION,
}

export const AWS_CONFIG = {
  identityPoolId: AWS_IDENTITY_POOL_ID,
  region: AWS_STORAGE_REGION,
  userPoolId: AWS_USER_POOL_ID,
  userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  Auth: AUTH_CONFIG,
  Storage: STORAGE_CONFIG,
}
