import React from 'react'
import { ROLES_CHOICES } from '../../../../config/constants'
import { Edit, SimpleForm, TextInput, required, SelectInput, BooleanInput } from 'react-admin'

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='show'>
      <TextInput source='name' validate={required()} />
      <BooleanInput source='enabled' />
      <SelectInput source='role' validate={required()} choices={ROLES_CHOICES} />
    </SimpleForm>
  </Edit>
)

export default edit
