import React from 'react'
import {
  Show,
  TextField,
  TabbedShowLayout,
  DateField,
  FunctionField,
  Tab,
  useTranslate,
  SelectField,
} from 'react-admin'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _join from 'lodash/join'
import { COUNTRY_CODES, SCOPE_OPTIONS } from '../../../../config/constants'
import { BeneficiaryDocuments } from '../../../organisms/'

const CustomShow = props => {
  const translate = useTranslate()
  const id = _get(props, 'match.params.id', '')

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={translate('resources.beneficiaries.fields.data')}>
          <TextField source='id' />
          <TextField source='nameEs' />
          <TextField source='nameEn' />
          <DateField source='createdAt' />
          <DateField source='updatedAt' />
          <TextField source='address' />
          <TextField source='city' />
          <DateField source='contractSignDate' />
          <FunctionField
            source='country'
            render={(r, s) => {
              const countryId = _get(r, s, '')
              const findCountry = _find(COUNTRY_CODES, { id: countryId })
              return findCountry?.name
            }}
          />
          <TextField source='email' />
          <TextField source='iban' />
          <TextField source='postalCode' />
          <TextField source='identificationNumber' />
        </Tab>
        <Tab label={translate('resources.beneficiaries.fields.files')}>
          <BeneficiaryDocuments id={id} />
        </Tab>
        <Tab label={translate('resources.beneficiaries.fields.displayedIn')}>
          <FunctionField
            source='availableInContext'
            render={(record, source) => {
              const context = _get(record, source, '')
              const formatContext = context?.map(item => translate(`resources.beneficiaries.fields.${item}`))

              return _join(formatContext, ',')
            }}
          />

          <SelectField source='scope' choices={SCOPE_OPTIONS} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const show = props => <CustomShow {...props} />

export default show
