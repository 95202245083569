import React from 'react'
import { ReferenceInput, TextInput, AutocompleteInput, SelectInput, Filter, DateInput, BooleanInput } from 'react-admin'
import { COUNTRY_CODES } from '../../../../config/constants'
import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'

const CustomFilters = ({ translate, ...props }) => {
  const formatCountryCodes = _orderBy(COUNTRY_CODES, [user => user.name], ['asc'])

  return (
    <Filter {...props}>
      <TextInput label={translate('resources.users.fields.referredBy')} source='publisher.referralCode||$contL' />
      <TextInput label={translate('resources.users.fields.invitedBy')} source='publisher.invitationCode||$contL' />
      <BooleanInput label={translate('resources.users.fields.agency')} source='publisher.agency||$eq' />
      <SelectInput
        label={translate('resources.users.fields.billingInfo.residenceCountry')}
        choices={formatCountryCodes}
        source='publisher.billingInfo.residenceCountry||$eq'
      />
      <SelectInput
        label={translate('resources.users.fields.billingInfo.companyCountry')}
        choices={formatCountryCodes}
        source='publisher.billingInfo.companyCountry||$eq'
      />

      <ReferenceInput
        label={translate('resources.publisher-earnings.fields.publisher')}
        source='publisher.id||$eq'
        reference='users'
        allowEmpty
        emptyText={translate('resources.publisher-earnings.fields.emptyPublisher')}
        filterToQuery={searchText => {
          return searchText ? { q: searchText, fieldKey: 'referredBy' } : {}
        }}
      >
        <AutocompleteInput optionText='nickname' optionValue='id' suggestionLimit={10} />
      </ReferenceInput>
      <DateInput
        label={translate('resources.publisher-earnings.fields.periodStart')}
        source='periodStart||$eq'
        autoComplete='off'
      />
    </Filter>
  )
}

CustomFilters.propTypes = {
  translate: PropTypes.func,
}

export default CustomFilters
