import React from 'react';
import { DateField, NumberField, NumberInput, required, TextField, TextInput } from 'react-admin'
import CurrencyField from '../../../atoms/currencyField'
import CurrencyInput from '../../../atoms/currencyInput'

const MetaFields = () => [
  <DateField key='createdAt' source='createdAt' />,
  <DateField key='updatedAt' source='updatedAt' />,
]
const ReadOnlyFields = () => [
  <TextField key='id' source='id' />,
  <TextField key='country' source='country' />,
  <TextField key='store' source='store' />,
  <TextField key='product' source='product' />,
  <TextField key='currency' source='currency' />,
  ];

const CreateOnlyFields = () => [
  <TextInput key='country' source='country' label='Country (ISO code-2)' />,
  <TextInput key='store' source='store' />,
  <TextInput key='product' source='product' />,
  <TextInput key='currency' source='currency' />,
]

const WriteFields = () => [
  <TextInput key='productID' source='productID' label='productID' validate={required()} />,
  <NumberInput key='credits' source='credits' min={1} step={1} validate={required()} />,
  <CurrencyInput key='price' source='price' validate={required()} />,
  <CurrencyInput key='proceeds' source='proceeds' validate={required()} />,
  <CurrencyInput key='pricePerCredit' source='pricePerCredit' validate={required()} />,
  <CurrencyInput key='proceedsPerCredit' source='proceedsPerCredit' validate={required()} />,
  <CurrencyInput key='creatorSharePerCredit' source='creatorSharePerCredit' validate={required()} />,
  <CurrencyInput key='partnerSharePerCredit' source='partnerSharePerCredit' validate={required()} />,
  ];
export const ReadFields = () => [
  ...ReadOnlyFields(),
  <TextField key='productID' source='productID' label='productID' />,
  <NumberField key='credits' source='credits' />,
  <CurrencyField key='price' source='price' />,
  <CurrencyField key='proceeds' source='proceeds' />,
  <CurrencyField key='pricePerCredit' source='pricePerCredit' />,
  <CurrencyField key='proceedsPerCredit' source='proceedsPerCredit' />,
  <CurrencyField key='creatorSharePerCredit' source='creatorSharePerCredit' />,
  <CurrencyField key='partnerSharePerCredit' source='partnerSharePerCredit' />,
  ...MetaFields()
  ];

export const CreateFields = () => [
  ...CreateOnlyFields(),
  ...WriteFields(),
]
export const EditFields = () => [
  ...ReadOnlyFields(),
  ...WriteFields(),
  ...MetaFields(),
]
