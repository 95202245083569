import React from 'react'
import { Edit, SimpleForm, TextInput, required, NumberInput } from 'react-admin'

const edit = props => (
  <Edit {...props}>
    <SimpleForm redirect='show'>
      <TextInput source='description' validate={required()} fullWidth multiline />
      <NumberInput source='value' validate={required()} fullWidth />
    </SimpleForm>
  </Edit>
)

export default edit
