import React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  DateField,
  NumberField,
  ArrayField,
  ReferenceField,
  BooleanField,
  Datagrid,
  FunctionField,
  withTranslate,
} from 'react-admin'
import _get from 'lodash/get'
import { priceFormatter } from '../../../../utils'
import compose from 'recompose/compose'
import { NATURAL_PERSON_TYPE, LEGAL_PERSON_TYPE } from '../../../../config/constants'
import PropTypes from 'prop-types'

const show = ({ translate, ...props }) => {
  const getPublisherType = type => {
    if (type) {
      return translate('resources.publisher-earnings.fields.agencia')
    }
    return translate('resources.publisher-earnings.fields.publisherUser')
  }

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField
          label={translate('resources.publisher-earnings.fields.publisher')}
          link='show'
          source='publisher.id'
          reference='users'
        >
          <TextField source='nickname' />
        </ReferenceField>

        <FunctionField
          source='publisher.agency'
          label={translate('resources.publisher-earnings.fields.publisherType')}
          render={(record, source) => {
            const agency = _get(record, source)
            return getPublisherType(agency)
          }}
        />
        <FunctionField
          source='publisher.billingInfo.personType'
          label={translate('resources.publisher-earnings.fields.personType')}
          render={(record, source) => {
            const personType = _get(record, source)
            if (personType === NATURAL_PERSON_TYPE) {
              return translate('resources.publisher-earnings.fields.personTypeNatural')
            } else if (personType === LEGAL_PERSON_TYPE) {
              return translate('resources.publisher-earnings.fields.personTypeLegal')
            }
            return ''
          }}
        />
        <FunctionField
          source='total'
          render={(record, source) => {
            let price = _get(record, source, '')
            price = price / 100
            return priceFormatter(price)
          }}
        />
        <NumberField source='purchases' />
        <DateField source='periodStart' />
        <DateField source='periodEnd' />
        <ArrayField source='items'>
          <Datagrid>
            <TextField source='type' />
            <ReferenceField
              label={translate('resources.publisher-earnings.fields.mgmUser')}
              sortable={false}
              link='show'
              source='mgmUser.id'
              reference='users'
            >
              <TextField source='nickname' />
            </ReferenceField>
            <FunctionField
              source='amount'
              render={(record, source) => {
                let price = _get(record, source, '')
                price = price / 100
                return priceFormatter(price)
              }}
            />
            <ReferenceField
              label={translate('resources.publisher-earnings.fields.beneficiary')}
              sortable={false}
              link='show'
              source='beneficiary.id'
              reference='beneficiaries'
            >
              <TextField source='nameEs' />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        <ArrayField source='contributions'>
          <Datagrid>
            <ReferenceField sortable={false} link='show' source='userId' reference='users'>
              <TextField source='nickname' />
            </ReferenceField>
            <ReferenceField sortable={false} link='show' source='beneficiaryId' reference='beneficiaries'>
              <TextField source='nameEs' />
            </ReferenceField>
            <NumberField sortable={false} source='percentage' />
          </Datagrid>
        </ArrayField>
        <BooleanField source='paid' />
        <TextField source='invoiceNumber' />
      </SimpleShowLayout>
    </Show>
  )
}

show.propTypes = {
  translate: PropTypes.func,
}

const enhanced = compose(withTranslate)

export default enhanced(show)
