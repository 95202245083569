import React from 'react'
import { Show, TextField, SimpleShowLayout, DateField, FunctionField } from 'react-admin'
import _get from 'lodash/get'
import { priceFormatter } from '../../../../utils'

const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' />
      <FunctionField
        source='price'
        render={(record, source) => {
          let price = _get(record, source, '')
          price = price / 100
          return priceFormatter(price)
        }}
      />
      <TextField source='appStoreId' />
      <TextField source='playStoreId' />
      <TextField source='videosAmount' />
      <TextField source='freeVideosAmount' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
    </SimpleShowLayout>
  </Show>
)

export default show
