import React from 'react'
import { ReferenceInput, SelectInput, Filter, DateInput } from 'react-admin'
import PropTypes from 'prop-types'

const CustomFilters = ({ translate, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      label={translate('resources.beneficiary-earnings.fields.beneficiarie')}
      source='beneficiaryId'
      reference='beneficiaries'
      allowEmpty
      emptyText={translate('resources.beneficiary-earnings.fields.emptyBeneficiarie')}
    >
      <SelectInput optionText='nameEs' />
    </ReferenceInput>
    <DateInput source='periodStart' autoComplete='off' />
    <DateInput source='periodEnd' autoComplete='off' />
  </Filter>
)

CustomFilters.propTypes = {
  translate: PropTypes.func,
}

export default CustomFilters
