import React from 'react';
import { NumberInput } from 'react-admin'
import * as PropTypes from 'prop-types'

/**
 * A react admin input to input currency values with a given granularity. It stores the values as
 * an integer, but let's the user edit it as a decimal that is the format the user will understand.
 * @param granularity Amount of precision (most currencies by default use 2)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CurrencyInput = ({ granularity = 6, ...props }) => {
  const div = Math.pow(10, granularity);
  return (<NumberInput
    parse={v => Math.floor(v * div)}
    format={v => parseFloat(v) / div}
    {...props} />);
}

CurrencyInput.propTypes = {
  granularity: PropTypes.number,
};
CurrencyInput.defaultProps = {
  addLabel: true,
  granularity: 6
};
export default CurrencyInput;
