import React, { useState, Fragment } from 'react'
import {
  Datagrid,
  TextField,
  List,
  NumberField,
  DateField,
  FunctionField,
  BooleanField,
  ReferenceField,
  useTranslate,
  useNotify,
  useRefresh,
  useUnselectAll,
  useListController,
  downloadCSV,
  EditButton,
} from 'react-admin'
import * as API from '../../../../api'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { priceFormatter } from '../../../../utils'
import { RegularButton } from '../../../atoms'
import styles from './styles'
import CustomFilters from './filters'
import moment from 'moment'
import ListActions from './actions'
import jsonExport from 'jsonexport/dist'
import { COUNTRY_CODES, NATURAL_PERSON_TYPE, LEGAL_PERSON_TYPE } from '../../../../config/constants'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const MyList = props => {
  const translate = useTranslate()
  const [isFetchingPaid, setIsFetchingPaid] = useState(false)
  const controllerProps = useListController(props)
  const { selectedIds } = controllerProps
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()

  const getPersonType = type => {
    if (type === NATURAL_PERSON_TYPE) {
      return translate('resources.users.fields.personTypeNatural')
    } else if (type === LEGAL_PERSON_TYPE) {
      return translate('resources.users.fields.personTypeLegal')
    }
    return ''
  }

  const getPublisherType = type => {
    if (type) {
      return translate('resources.publisher-earnings.fields.agency')
    }
    return translate('resources.publisher-earnings.fields.publisherUser')
  }

  const handleSetPaid = async ids => {
    try {
      setIsFetchingPaid(true)
      await API.setPublishersPaid({ ids })
      refresh()
      unselectAll('publisher-earnings')
      notify('resources.publisher-earnings.notifications.successPaid', 'info', { smart_count: 1 })
    } catch {
      notify('generalError', 'error', { smart_count: 1 })
    } finally {
      setIsFetchingPaid(false)
    }
  }

  const exporter = posts => {
    const postsForExport = posts?.map(post => {
      const items = post?.items
      const findBeneficiary = _find(items, { type: 'beneficiary' })
      const amountDonation = findBeneficiary?.amount || 0
      const residenceCountry = _get(
        _find(COUNTRY_CODES, { id: _get(post, 'publisher.billingInfo.residenceCountry') }),
        'name',
        ''
      )
      const documentType = _get(post, 'publisher.billingInfo.documentType')
        ? translate(`resources.users.fields.${_get(post, 'publisher.billingInfo.documentType', '')}`)
        : ''
      const companyCountry = _get(
        _find(COUNTRY_CODES, { id: _get(post, 'publisher.billingInfo.companyCountry') }),
        'name',
        ''
      )

      return {
        [translate('resources.publisher-earnings.fields.publisherId')]: post?.publisher?.creatorId,
        [translate('resources.publisher-earnings.fields.publisherType')]: getPublisherType(post?.publisher?.agency),
        [translate('resources.users.fields.personType')]: getPersonType(post?.publisher?.billingInfo?.personType),
        [translate('resources.publisher-earnings.fields.publisher')]: post?.publisher?.nickname,
        [translate('resources.publisher-earnings.fields.totalVideoRevenue')]: priceFormatter(
          post?.totalVideoRevenue / 100
        ),
        [translate('resources.publisher-earnings.fields.totalInvitationsRevenue')]: priceFormatter(
          post?.totalInvitationsRevenue / 100
        ),
        [translate('resources.publisher-earnings.fields.totalRevenue')]: priceFormatter(post?.totalRevenue / 100),
        [translate('resources.publisher-earnings.fields.donationPercent')]: post?.contributions[0]?.percentage,
        [translate('resources.publisher-earnings.fields.amountDonation')]: priceFormatter(amountDonation / 100),
        [translate('resources.publisher-earnings.fields.beneficiary')]: findBeneficiary?.beneficiary?.nameEs,
        [translate('resources.publisher-earnings.fields.total')]: priceFormatter(post?.total / 100),
        [translate('resources.publisher-earnings.fields.purchases')]: post?.purchases,
        [translate('resources.publisher-earnings.fields.periodStart')]:
          post?.periodStart && moment(post?.periodStart).format('DD/MM/YYYY'),
        [translate('resources.publisher-earnings.fields.periodEnd')]:
          post?.periodEnd && moment(post?.periodEnd).format('DD/MM/YYYY'),
        [translate('resources.publisher-earnings.fields.paid')]: post?.paid,
        [translate('resources.users.fields.invoiceCount')]: post?.publisher?.invoiceCount + '',
        [translate('resources.publisher-earnings.fields.invoiceNumber')]: post?.invoiceNumber,
        [translate('resources.users.fields.billingInfo.name')]: post?.publisher?.billingInfo?.name,
        [translate('resources.users.fields.billingInfo.lastName')]: post?.publisher?.billingInfo?.lastName,
        [translate('resources.users.fields.billingInfo.residenceCountry')]: residenceCountry,
        [translate('resources.users.fields.billingInfo.residenceAddress')]: post?.publisher?.billingInfo
          ?.residenceAddress,
        [translate('resources.users.fields.billingInfo.residenceCity')]: post?.publisher?.billingInfo?.residenceCity,
        [translate('resources.users.fields.billingInfo.residencePostalCode')]: post?.publisher?.billingInfo
          ?.residencePostalCode,
        [translate('resources.users.fields.billingInfo.birthDate')]:
          post?.publisher?.billingInfo?.birthDate &&
          moment(post?.publisher?.billingInfo?.birthDate).format('DD/MM/YYYY'),
        [translate('resources.users.fields.billingInfo.documentType')]: documentType,
        [translate('resources.users.fields.billingInfo.documentNumber')]: post?.publisher?.billingInfo?.documentNumber,
        [translate('resources.users.fields.billingInfo.companyName')]: post?.publisher?.billingInfo?.companyName,
        [translate('resources.users.fields.billingInfo.companyEmail')]: post?.publisher?.billingInfo?.companyEmail,
        [translate('resources.users.fields.billingInfo.companyCity')]: post?.publisher?.billingInfo?.companyCity,
        [translate('resources.users.fields.billingInfo.companyAddress')]: post?.publisher?.billingInfo?.companyAddress,
        [translate('resources.users.fields.billingInfo.companyPostalCode')]: post?.publisher?.billingInfo
          ?.companyPostalCode,
        [translate('resources.users.fields.billingInfo.companyPostalCode')]: companyCountry,
        [translate('resources.users.fields.billingInfo.companyVat')]: post?.publisher?.billingInfo?.companyVat,
        [translate('resources.users.fields.billingInfo.companyIban')]: post?.publisher?.billingInfo?.companyIban,
        [translate('resources.users.fields.billingInfo.contractSignDate')]:
          post?.publisher?.billingInfo?.contractSignDate &&
          moment(post?.publisher?.billingInfo?.contractSignDate).format('DD/MM/YYYY'),
      }
    })

    jsonExport(
      postsForExport,
      (err, csv) => downloadCSV(csv, 'ganancias-creador') // download as 'ganancias-creador.csv` file
    )
  }

  return (
    <div>
      {selectedIds.length > 0 && (
        <div style={styles.buttonWrapper}>
          <RegularButton
            isFetching={isFetchingPaid}
            label={translate('resources.publisher-earnings.fields.pay')}
            disabled={isFetchingPaid}
            onClick={() => handleSetPaid(selectedIds)}
          />
        </div>
      )}
      <List
        {...props}
        actions={<ListActions />}
        sort={DEFAULT_SORT}
        filters={<CustomFilters translate={translate} />}
        exporter={exporter}
        bulkActionButtons={<Fragment />}
      >
        <Datagrid rowClick='show'>
          <TextField source='id' />
          <ReferenceField
            label={translate('resources.publisher-earnings.fields.publisher')}
            link='show'
            source='publisher.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>

          <FunctionField
            source='publisher.agency'
            label={translate('resources.publisher-earnings.fields.publisherType')}
            render={(record, source) => {
              const agency = _get(record, source)
              return getPublisherType(agency)
            }}
          />

          <FunctionField
            source='publisher.billingInfo.personType'
            label={translate('resources.users.fields.personType')}
            render={(record, source) => {
              const personType = _get(record, source)
              return getPersonType(personType)
            }}
          />

          <FunctionField
            source='totalVideoRevenue'
            render={(record, source) => {
              const totalRevenue = _get(record, source)
              return priceFormatter(totalRevenue / 100)
            }}
          />

          <FunctionField
            source='totalInvitationsRevenue'
            render={(record, source) => {
              const totalRevenue = _get(record, source)
              return priceFormatter(totalRevenue / 100)
            }}
          />
          <FunctionField
            source='totalRevenue'
            render={(record, source) => {
              const totalRevenue = _get(record, source)
              return priceFormatter(totalRevenue / 100)
            }}
          />
          <FunctionField
            sortable={false}
            source='contributions'
            label={translate('resources.publisher-earnings.fields.donationPercent')}
            render={(record, source) => {
              const contributions = _get(record, source, '')
              return contributions[0]?.percentage
            }}
          />
          <FunctionField
            sortable={false}
            source='items'
            label={translate('resources.publisher-earnings.fields.amountDonation')}
            render={(record, source) => {
              const items = _get(record, source, '')
              const findBeneficiary = _find(items, { type: 'beneficiary' })
              const amount = findBeneficiary?.amount || 0
              return priceFormatter(amount / 100)
            }}
          />
          <FunctionField
            sortable={false}
            source='items'
            label={translate('resources.publisher-earnings.fields.beneficiary')}
            render={(record, source) => {
              const items = _get(record, source, '')
              const findBeneficiary = _find(items, { type: 'beneficiary' })
              return findBeneficiary?.beneficiary?.nameEs
            }}
          />
          <FunctionField
            source='total'
            render={(record, source) => {
              const price = _get(record, source, '')
              return priceFormatter(price / 100)
            }}
          />
          <NumberField source='purchases' />
          <DateField source='periodStart' />
          <DateField source='periodEnd' />
          <BooleanField source='paid' />
          <TextField source='invoiceNumber' />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  )
}

const list = props => <MyList {...props} />

export default list
