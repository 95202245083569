import React, { useState } from 'react'
import { Datagrid, TextField, useNotify, EditButton, List, BooleanField, withTranslate } from 'react-admin'
import { BulkActionsButtons } from '../../../molecules'
import compose from 'recompose/compose'
import { Typography, TextField as MaterialTextField } from '@material-ui/core'
import * as API from '../../../../api'
import { useListController } from 'react-admin'
import PropTypes from 'prop-types'
import styles from './styles'
import { RegularButton } from '../../../atoms'
import CustomFilters from './filters'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const MyList = ({ translate, ...props }) => {
  const [invitationNumber, setInvitationNumber] = useState('')
  const [isFetchingInvitation, setIsFetchingInvitation] = useState(false)
  const controllerProps = useListController(props)
  const { selectedIds } = controllerProps
  const notify = useNotify()

  const handleSendInvitations = async (invitationCount, userIds) => {
    try {
      if (invitationCount > 0) {
        setIsFetchingInvitation(true)
        await API.addUserInvitations({ invitationCount, userIds })
        setInvitationNumber('')
        notify('resources.users.notifications.successInvitations', 'info', { smart_count: 1 })
      }
    } catch {
      notify('generalError', 'error', { smart_count: 1 })
    } finally {
      setIsFetchingInvitation(false)
    }
  }

  return (
    <div>
      {selectedIds.length > 0 && (
        <div style={styles.inviationsWrapper}>
          <Typography variant='body2' component='p'>
            {translate('resources.users.fields.sendInvitations')}
          </Typography>
          <div style={styles.invitationInputWrapper}>
            <MaterialTextField
              value={invitationNumber}
              onChange={e => setInvitationNumber(e.target.value)}
              fullWidth
              type='number'
              placeholder={translate('resources.users.fields.invitationCount')}
            />
          </div>
          <div style={styles.buttonWrapper}>
            <RegularButton
              isFetching={isFetchingInvitation}
              label={translate('resources.users.fields.send')}
              disabled={parseInt(invitationNumber) <= 0 || !invitationNumber || isFetchingInvitation || false}
              onClick={() => handleSendInvitations(parseInt(invitationNumber), selectedIds)}
            />
          </div>
        </div>
      )}
      <List
        {...props}
        sort={DEFAULT_SORT}
        filters={<CustomFilters translate={translate} />}
        exporter={false}
        bulkActionButtons={<BulkActionsButtons />}
      >
        <Datagrid rowClick='show'>
          <TextField source='id' />
          <TextField source='name' />
          <TextField source='nickname' />
          <TextField source='email' />
          <BooleanField source='publisher' textAlign='center' />
          <TextField source='creatorId' />
          <TextField source='invoiceCount' />
          <BooleanField source='enabled' textAlign='center' />
          <BooleanField source='banned' textAlign='center' />
          <BooleanField source='featured' textAlign='center' />
          <BooleanField source='billingInfo.checked' textAlign='center' />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  )
}

MyList.propTypes = {
  translate: PropTypes.any,
}

const list = props => <MyList {...props} />

const enharced = compose(withTranslate)

export default enharced(list)
