import React from 'react'
import { Edit, SimpleForm, NumberInput, required, Toolbar, SaveButton, TextInput } from 'react-admin'

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const edit = props => {
  const minZero = message => value => (value && value < 0 ? message : undefined)
  const validateNumber = [minZero('resources.products.errors.minZero'), required()]

  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect='show' toolbar={<CustomToolbar />}>
        <NumberInput step={0.1} format={v => v / 100} parse={v => parseFloat(v) * 100} source='price' />
        <NumberInput step={1} source='videosAmount' validate={validateNumber} />
        <NumberInput step={1} source='freeVideosAmount' validate={validateNumber} />
        <TextInput source='appStoreId' />
        <TextInput source='playStoreId' />
      </SimpleForm>
    </Edit>
  )
}

export default edit
