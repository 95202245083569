export default {
  btnWrapper: {
    marginBottom: '15px',
  },
  title: {
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  back: {
    cursor: 'pointer',
  },
  spinnerWrapper: {
    textAlign: 'center',
    padding: '30px 15px',
  },
}
