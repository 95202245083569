import React from 'react'
import { Show, TextField, SimpleShowLayout } from 'react-admin'

const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' />
      <TextField source='name' />
      <TextField source='nickname' />
      <TextField source='email' />
      <TextField source='role' />
    </SimpleShowLayout>
  </Show>
)

export default show
