import React from 'react';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  required,
  Toolbar,
  DeleteButton,
  AutocompleteInput,
  useTranslate,
  DateInput,
  useEditController,
} from 'react-admin'
import _orderBy from 'lodash/orderBy'
import SaveWithNoteButton from './saveButton.js'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import {
  NATURAL_PERSON_TYPE,
  LEGAL_PERSON_TYPE,
  COUNTRY_CODES,
  NATIONAL_ID,
  PASSPORT,
  CREATOR_TYPE_OPTIONS,
  PUBLISHER_STATUS
} from '../../../../config/constants'

const useStyles = makeStyles({
  input: {
    width: 256,
  },
})

const PostCreateToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveWithNoteButton {...props} />
    <DeleteButton {...props} undoable={false} />
  </Toolbar>
)

const EditUser = props => {
  const editData = useEditController(props)
  const resourceId = editData?.record?.id
  const isPublisher = editData?.record?.publisher
  const isPromise = editData?.record?.publisherType === "promise"
  const translate = useTranslate()
  const materialStyles = useStyles()
  const formatCountryCodes = _orderBy(COUNTRY_CODES, [user => user.name], ['asc'])

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostCreateToolbar {...props} />} redirect='show'>
        <TextInput source='name' validate={required()} />
        <TextInput source='nickname' validate={required()} />
        <BooleanInput source='publisher' />
        <BooleanInput source='enabled' />
        <BooleanInput source='banned' />
        <BooleanInput source='agency' />
        <BooleanInput source='billingInfo.checked' />

        {isPublisher && (
          <>
            <BooleanInput source='freeVideos' />
            <BooleanInput source='featured' label={translate('resources.users.fields.featured')} />
            <br />
            <SelectInput
              className={materialStyles.input}
              source='publisherType'
              label={translate('resources.users.fields.publisherType')}
              choices={CREATOR_TYPE_OPTIONS}
            />
            <br />
            {
              isPromise &&
              <>
              <SelectInput
              className={materialStyles.input}
              source='publisherStatus'
              label={translate('resources.users.fields.publisherStatus')}
              choices={PUBLISHER_STATUS} />
              <br />
              </>
            }
                        
            <ReferenceInput
              className={materialStyles.input}
              source='contributions[0].beneficiaryId'
              label={translate('resources.users.fields.beneficiary')}
              reference='beneficiaries'
            >
              <SelectInput optionText='nameEs' />
            </ReferenceInput>
            <br />
            <NumberInput
              className={materialStyles.input}
              source='contributions[0].percentage'
              label={translate('resources.users.fields.percentage')}
            />
            <br />
            <ReferenceInput
              className={materialStyles.input}
              allowEmpty
              emptyText={translate('resources.users.fields.emptyReferredBy')}
              filterToQuery={searchText => {
                return searchText ? { q: searchText, fieldKey: 'referredBy' } : {}
              }}
              label={translate('resources.users.fields.referredBy')}
              source='referredBy.id'
              reference='users'
            >
              <AutocompleteInput optionText='nickname' optionValue='id' suggestionLimit={10} />
            </ReferenceInput>
          </>
        )}

        <SelectInput
          source='billingInfo.personType'
          label={translate('resources.users.fields.personType')}
          choices={[
            { id: NATURAL_PERSON_TYPE, name: translate('resources.users.fields.personTypeNatural') },
            { id: LEGAL_PERSON_TYPE, name: translate('resources.users.fields.personTypeLegal') },
          ]}
        />

        <TextInput source='billingInfo.name' />
        <TextInput source='billingInfo.lastName' />
        <SelectInput source='billingInfo.residenceCountry' choices={formatCountryCodes} />
        <TextInput source='billingInfo.residenceAddress' />
        <TextInput source='billingInfo.residenceCity' />
        <NumberInput source='billingInfo.residencePostalCode' />
        <DateInput source='billingInfo.birthDate' />
        <SelectInput
          source='billingInfo.documentType'
          choices={[
            { id: PASSPORT, name: translate('resources.users.fields.passport') },
            { id: NATIONAL_ID, name: translate('resources.users.fields.national_id') },
          ]}
        />

        <ReferenceInput
          allowEmpty
          emptyText={translate('resources.users.fields.emptyDocument')}
          label={translate('resources.users.fields.billingInfo.photoDocument')}
          source='billingInfo.photoDocument.id'
          reference='documents'
          filter={{ 'userId||eq': resourceId }}
        >
          <SelectInput optionText='description' optionValue='id' />
        </ReferenceInput>

        <ReferenceInput
          allowEmpty
          emptyText={translate('resources.users.fields.emptyDocument')}
          label={translate('resources.users.fields.billingInfo.holdingPhotoDocument')}
          source='billingInfo.holdingPhotoDocument.id'
          reference='documents'
          filter={{ 'userId||eq': resourceId }}
        >
          <SelectInput optionText='description' optionValue='id' />
        </ReferenceInput>

        <ReferenceInput
          allowEmpty
          emptyText={translate('resources.users.fields.emptyDocument')}
          label={translate('resources.users.fields.billingInfo.bankCertificateDocument')}
          source='billingInfo.bankCertificateDocument.id'
          reference='documents'
          filter={{ 'userId||eq': resourceId }}
        >
          <SelectInput optionText='description' optionValue='id' />
        </ReferenceInput>

        <ReferenceInput
          allowEmpty
          emptyText={translate('resources.users.fields.emptyDocument')}
          label={translate('resources.users.fields.billingInfo.companyCertificateDocument')}
          source='billingInfo.companyCertificateDocument.id'
          reference='documents'
          filter={{ 'userId||eq': resourceId }}
        >
          <SelectInput optionText='description' optionValue='id' />
        </ReferenceInput>

        <TextInput source='billingInfo.documentNumber' />
        <TextInput source='billingInfo.companyName' />
        <TextInput source='billingInfo.companyEmail' />
        <TextInput source='billingInfo.companyCity' />
        <TextInput source='billingInfo.companyAddress' />
        <NumberInput source='billingInfo.companyPostalCode' />
        <SelectInput source='billingInfo.companyCountry' choices={formatCountryCodes} />
        <TextInput source='billingInfo.companyVat' />
        <TextInput source='billingInfo.companyIban' />
        <DateInput source='billingInfo.contractSignDate' />
        <NumberInput source='invoiceCount' validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

const edit = props => <EditUser {...props} />

export default edit
