import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { CompletePasswordForm } from '../../organisms'
import { ThemeProvider } from '@material-ui/styles'
import theme from '../../../config/theme'

const CompletePassword = () => {
  return (
    <ThemeProvider theme={theme}>
      <div style={styles.root}>
        <div style={styles.formWrapper}>
          <CompletePasswordForm />
        </div>
      </div>
    </ThemeProvider>
  )
}

const enharce = withStyles(styles)

export default enharce(CompletePassword)
