import React from 'react'
import _orderBy from 'lodash/orderBy'
import { Create, SimpleForm, TextInput, required, SelectInput, DateInput, SelectArrayInput } from 'react-admin'
import { COUNTRY_CODES, AVAILABLE_CONTEXT_OPTIONS, SCOPE_OPTIONS } from '../../../../config/constants'
import { validateBeneficiariesScope } from '../../../../utils'

const create = props => {
  const formatCountryCodes = _orderBy(COUNTRY_CODES, [user => user.name], ['asc'])

  return (
    <Create {...props}>
      <SimpleForm redirect='list' validate={validateBeneficiariesScope}>
        <TextInput source='nameEs' validate={required()} />
        <TextInput source='nameEn' validate={required()} />
        <TextInput source='address' />
        <TextInput source='city' />
        <SelectInput source='country' choices={formatCountryCodes} />
        <DateInput source='contractSignDate' />
        <TextInput source='email' parse={value => value.toLowerCase()} />
        <TextInput source='iban' />
        <TextInput source='postalCode' />
        <SelectArrayInput source='availableInContext' choices={AVAILABLE_CONTEXT_OPTIONS} validate={required()} />
        <SelectInput source='scope' choices={SCOPE_OPTIONS} validate={required()} />
        <TextInput source='identificationNumber' />
      </SimpleForm>
    </Create>
  )
}

export default create
