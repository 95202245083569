export default {
  root: {
    padding: '15px',
  },
  userWrapper: {
    marginBottom: '30px',
  },
  formWrapper: {
    maxWidth: '270px',
    marginBottom: '60px',
  },
  title: {
    fontWeight: 'bold',
  },
  pageTitle: {
    marginBottom: '30px',
    textDecoration: 'underline',
  },
}
