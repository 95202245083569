import React from 'react'
import { Datagrid, TextField, List, EditButton } from 'react-admin'
import { BulkActionsButtons } from '../../../molecules'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const list = props => (
  <List {...props} sort={DEFAULT_SORT} exporter={false} bulkActionButtons={<BulkActionsButtons />}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='code' />
      <TextField source='nameEs' />
      <TextField source='nameEn' />
      <EditButton />
    </Datagrid>
  </List>
)

export default list
