export default {
  root: {
    padding: '15px',
  },
  fieldWrapper: {
    marginBottom: '15px',
  },
  tableWrapper: {
    display: 'inline-flex',
  },
}
