import React from 'react'
import {
  List,
  Datagrid,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  Filter,
  TextInput,
  EditButton,
  TextField,
  ImageField,
  NumberField,
  BooleanField,
  useTranslate,
} from 'react-admin'
import PropTypes from 'prop-types'
import { BulkActionsButtons } from '../../../molecules'

const Filters = props => {
  const translate = useTranslate()

  return (
    <Filter {...props}>
      <ReferenceInput
        label={translate('resources.videos.fields.category')}
        source='category||eq'
        reference='categories'
      >
        <SelectInput optionText='code' emptyText={translate('resources.videos.fields.all')} />
      </ReferenceInput>
      <TextInput
        autoComplete='off'
        label={translate('resources.videos.fields.alias')}
        source='owner||$contL'
        reference='users'
      />
      <BooleanInput
        label={translate('resources.videos.fields.highlighted')}
        source='highlighted||eq'
        reference='highlighted'
      />
    </Filter>
  )
}

const CustomStatus = ({ translate, source, record = {} }) => (
  <span>{translate(`resources.videos.fields.${record[source]}`)}</span>
)

CustomStatus.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func,
}

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const CustomEdit = props => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      sort={DEFAULT_SORT}
      filters={<Filters />}
      exporter={false}
      bulkActionButtons={<BulkActionsButtons />}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <TextField source='title' />
        <NumberField source='purchasesCount' />
        <ImageField source='thumbnail' />
        <TextField source='owner.nickname' />
        <TextField source='category.code' />
        <CustomStatus translate={translate} source='status' />
        <BooleanField source='free' />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const list = props => <CustomEdit {...props} />

export default list
