import React from 'react'
import { ROLES_CHOICES } from '../../../../config/constants'
import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin'

const create = props => (
  <Create {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' validate={required()} />
      <TextInput source='email' validate={required()} parse={value => value.toLowerCase()} />
      <SelectInput source='role' validate={required()} choices={ROLES_CHOICES} />
    </SimpleForm>
  </Create>
)

export default create
