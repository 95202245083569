import { roles } from './config/api'
import _find from 'lodash/find'
import _isNil from 'lodash/isNil'
import { NATIONAL_SCOPE } from './config/constants'

export const isAdminUser = permissions => {
  const isRoleAdmin = _find(permissions, permission => permission === roles.ROLE_ADMIN)
  return !_isNil(isRoleAdmin)
}

export const isSuperAdminUser = permissions => {
  const isRoleAdmin = _find(permissions, permission => permission === roles.ROLE_SUPERADMIN)
  return !_isNil(isRoleAdmin)
}

export const isMarketingUser = permissions => {
  const isRoleMarketing = _find(permissions, permission => permission === roles.ROLE_MARKETING)
  return !_isNil(isRoleMarketing)
}

export function validatePassword(password) {
  var re = /^(?=.*\d)(?=.*[\u0021-\u002b\u003c-\u0040])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$/
  return re.test(password)
}

export const priceFormatter = price => {
  const formatter = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
  })

  return formatter.format(price)
}

export const showIfMarketingUser = permissions => {
  return isMarketingUser(permissions) || !permissions?.length
}

export const validateBeneficiariesScope = values => {
  const errors = {}
  if (values.scope === NATIONAL_SCOPE && !values.country) {
    errors.country = 'ra.validation.required'
  }
  return errors
}
