export default {
  btnsWrapper: {
    textAlign: 'center',
  },
  btnWrapper: {
    marginBottom: '15px',
  },
  title: {
    textAlign: 'center',
  },
  back: {
    cursor: 'pointer',
  },
}
