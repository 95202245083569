import React from 'react'
import { Show, TextField, SimpleShowLayout, NumberField } from 'react-admin'

const show = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='id' />
      <TextField source='description' />
      <NumberField source='value' />
    </SimpleShowLayout>
  </Show>
)

export default show
