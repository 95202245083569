import colors from '../../../assets/colors'

export default {
  root: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    color: colors.blue,
  },
}
