import React from 'react'
import { Datagrid, TextField, NumberField, List, EditButton } from 'react-admin'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const list = props => (
  <List {...props} sort={DEFAULT_SORT} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick='show'>
      <TextField source='id' />
      <TextField source='description' />
      <NumberField source='value' />
      <EditButton />
    </Datagrid>
  </List>
)

export default list
