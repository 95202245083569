import * as api from '../config/api'
import qs from 'qs'
import axios from 'axios'

export const login = async ({ username, password }) => {
  const request = new Request(`${api.BASE_URL}/api/login_check`, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const deleteComment = async id => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/comment-reports/${id}/reaction`
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const deleteVideo = async id => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/videos/${id}`
  return axios.delete(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getTransationsByUser = async ({ userId, limit, page, offset, sort }) => {
  const token = localStorage.getItem('token')

  const params = {
    limit: limit || 5,
    page: page || 1,
    offset: offset || 0,
    sort: sort || ['createdAt,DESC'],
    filter: `user.id||eq||${userId}`,
  }

  const url = `${api.BASE_URL}/api/transactions?join=beneficiary&${qs.stringify(params, {
    skipNulls: true,
  })}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getDocuments = async ({ source, id, limit, page, offset, sort }) => {
  const token = localStorage.getItem('token')

  const params = {
    limit: limit || 5,
    page: page || 1,
    offset: offset || 0,
    sort: sort || ['createdAt,DESC'],
    filter: `${source}.id||eq||${id}`,
  }

  const url = `${api.BASE_URL}/api/documents?join=${source}&${qs.stringify(params, {
    skipNulls: true,
  })}`

  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getDocumentUrl = async documentId => {
  const token = localStorage.getItem('token')

  const url = `${api.BASE_URL}/api/documents/${documentId}/signed-url`

  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const postDocument = async ({ id, idLabel, file, description }) => {
  const token = localStorage.getItem('token')

  const formData = new FormData()
  formData.append('file', file)
  formData.append('description', description)
  formData.append(idLabel, id)

  const url = `${api.BASE_URL}/api/documents`

  return axios.post(url, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getBeneficiaryEarnings = async (beneficiaryId, filter) => {
  const token = localStorage.getItem('token')

  const url = `${api.BASE_URL}/api/beneficiary-earnings/${beneficiaryId}?join=items&join=items.publisher&${qs.stringify(
    filter,
    {
      skipNulls: true,
    }
  )}`
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const updateUserContributions = async ({ userId, data }) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/users/${userId}/contributions`
  return axios.put(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const addUserInvitations = async ({ invitationCount, userIds }) => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/users/add-invitations`
  return axios.post(
    url,
    { invitationCount, userIds },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export const setPublishersPaid = async data => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/publisher-earnings/set-paid`
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const changeMeData = data => {
  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/users/me`
  return axios.patch(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

export const getMaximumNumberOfPurchases = async (videoId) => {
  if (!videoId) {
    return;
  }

  const token = localStorage.getItem('token')
  const url = `${api.BASE_URL}/api/videos/${videoId}/available`;

  let data = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return data?.data?.amount || 0;
}

export const purcharseVideoViews = async (videoId, quantity) => {
  const url = `${api.BASE_URL}/api/videos/${videoId}/purchase`;
  const token = localStorage.getItem('token')
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify({ "amount": quantity }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  await fetch(request);
}