import React from 'react'
import {
  TextField,
  List,
  withTranslate,
  NumberField,
  DateField,
  BooleanField,
} from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import ListActions from './actions'
import CustomFilters from './filters'
import CustomizableDatagrid from 'ra-customizable-datagrid';
import CurrencyField from '../../../atoms/currencyField'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const MyList = ({ translate, ...props }) => {


  return (
    <List
      {...props}
      sort={DEFAULT_SORT}
      filters={<CustomFilters translate={translate} />}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      <CustomizableDatagrid rowClick={null}>
        <DateField showTime source='createdAt' />
        <TextField source='id' />
        <TextField source='user.id' />
        <TextField source='video.id' />
        <TextField source='transaction.id' />
        <TextField source='transaction.countryCode' />
        <TextField source='transaction.currencyCode' />
        <BooleanField source='counted' />
        <CurrencyField source="pricingCreatorSharePerCreditLocal" currencySource="transaction.currencyCode" />
        <CurrencyField source="pricingPartnerSharePerCreditLocal" currencySource="transaction.currencyCode" />
        <NumberField source="pricingExchangeRate" />
        <CurrencyField source="pricingCreatorSharePerCredit" currencySource="transaction.currencyCode" />
        <CurrencyField source="pricingPartnerSharePerCredit" currencySource="transaction.currencyCode" />
      </CustomizableDatagrid>
    </List>
  )
}

MyList.propTypes = {
  translate: PropTypes.any,
}

const list = props => <MyList {...props} />

const enharced = compose(withTranslate)

export default enharced(list)
