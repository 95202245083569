import React from 'react'
import {
  Show,
  TextField,
  SimpleShowLayout,
  FunctionField,
  ReferenceField,
  ImageField,
  DateField,
  BooleanField,
  useTranslate,
  NumberField,
} from 'react-admin'
import PropTypes from 'prop-types'
import { showIfMarketingUser } from '../../../../utils'
import _get from 'lodash/get'
import ReactPlayer from 'react-player'

const VideoField = ({ source, record = {} }) => {
  const videoUrl = _get(record, source)
  if (videoUrl) {
    return <ReactPlayer url={videoUrl} controls />
  } else {
    return '-----'
  }
}

VideoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

VideoField.defaultProps = { addLabel: true }

const CustomShow = props => {
  const translate = useTranslate()
  const { permissions } = props

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <FunctionField
          label={translate('resources.videos.fields.status')}
          render={record => translate(`resources.videos.fields.${record.status}`)}
        />
        <TextField source='title' />
        <TextField source='originalName' />
        <BooleanField source='highlighted' />
        <BooleanField source='free' />
        <NumberField source='purchasesCount' />
        <ImageField source='thumbnail' />

        {showIfMarketingUser(permissions) ? (
          <TextField label={translate('resources.videos.fields.owner')} source='owner.nickname' />
        ) : (
          <ReferenceField
            link='show'
            label={translate('resources.videos.fields.owner')}
            source='owner.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>
        )}

        <ReferenceField
          link='show'
          label={translate('resources.videos.fields.category')}
          source='category.id'
          reference='categories'
        >
          <TextField source='code' />
        </ReferenceField>
        <VideoField source='trailer' />
        <VideoField label={translate('resources.videos.fields.video')} source='url' />
        <DateField source='createdAt' />
        <DateField source='updatedAt' />
      </SimpleShowLayout>
    </Show>
  )
}

CustomShow.propTypes = {
  permissions: PropTypes.array,
}

const show = props => <CustomShow {...props} />

export default show
