import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import styles from './styles'
import { RegularInput, RegularButton } from '../../atoms'
import { withTranslate, useNotify } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import theme from '../../../config/theme'

const ChangeUserPassForm = ({ translate }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPass, setRepeatPass] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const notify = useNotify()

  useEffect(() => {
    if (oldPassword && password && repeatPass && password === repeatPass) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [password, repeatPass, oldPassword])

  const onSubmit = async e => {
    e.preventDefault()

    setIsFetching(true)
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      await Auth.changePassword(user, oldPassword, password)
      notify('myProfile.dataChanged', 'info')
      setPassword('')
      setRepeatPass('')
      setOldPassword('')
    } catch (error) {
      notify('generalError', 'warning')
    }
    setIsFetching(false)
  }

  const handleOldPassword = value => {
    setOldPassword(value)
  }

  const handlePassword = value => {
    setPassword(value)
  }

  const handleRepeatPass = value => {
    setRepeatPass(value)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <form onSubmit={onSubmit} autoComplete='off'>
        <Typography style={styles.title} variant='body1' color='inherit'>
          {translate('myProfile.changePass')}
        </Typography>
        <RegularInput
          value={oldPassword}
          onChange={handleOldPassword}
          label={translate('myProfile.actualPass')}
          type='password'
        />
        <RegularInput
          value={password}
          onChange={handlePassword}
          label={translate('myProfile.password')}
          type='password'
        />
        <RegularInput
          value={repeatPass}
          onChange={handleRepeatPass}
          label={translate('myProfile.repeatPass')}
          type='password'
        />
        <Typography style={styles.validatePassword} variant='body2' component='p'>
          *{translate('myProfile.validatePassword')}
        </Typography>
        <div style={styles.btnWrapper}>
          <RegularButton
            isFetching={isFetching}
            disabled={disabledButton}
            type='submit'
            label={translate('myProfile.change')}
          />
        </div>
      </form>
    </MuiThemeProvider>
  )
}

ChangeUserPassForm.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withRouter, withStyles(styles), withTranslate)

export default enharce(ChangeUserPassForm)
