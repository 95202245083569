export default {
  delete: 'Eliminar',
  confirmDeleteContent: 'Se van a borrar los elementos seleccionados. ¿Confirmar acción?',
  myProfile: {
    title: 'Mi perfil',
    password: 'Nueva contraseña',
    change: 'Modificar',
    dataChanged: 'Datos modificados',
    repeatPass: 'Repetir contraseña',
    userName: 'Nombre',
    actualPass: 'Contraseña actual',
    changePass: 'Cambiar contraseña',
    changeName: 'Cambiar nombre',
    validatePassword:
      'La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.',
  },
  customLoginPage: {
    forgotPass: '¿Olvidaste tu contraseña?',
  },
  completePassword: {
    title: 'Completar acceso',
    user: 'Usuario',
    tempPass: 'Contraseña Temporal',
    newPass: 'Nueva contraseña',
    send: 'Enviar',
    back: 'Volver',
    validatePassword:
      'La contraseña debe tener entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter.',
  },
  forgotEmailForm: {
    title: 'Recuperar contraseña',
    user: 'Usuario',
    emailSend: 'Se ha enviado un código a su correo electrónico para recuperar la contraseña',
    send: 'Enviar',
    back: 'Volver',
    passwordChanged: 'Contraseña cambiada',
    code: 'Código',
    newPassword: 'Nueva contraseña',
  },
  generalError: 'Ha ocurrido un error',
  passwordChanged: 'Contraseña actualizada',
  resources: {
    users: {
      name: 'Usuarios',
      fields: {
        id: 'Id',
        name: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Email',
        country: 'País appstore/playstore',
        updatedAt: 'Actualizado el',
        originalName: 'Nombre original',
        publisher: 'Creador de contenido',
        freeVideos: 'Free videos',
        creatorId: 'Id creador',
        enabled: 'Activo',
        nickname: 'Alias',
        banned: 'Baneado',
        availableVideos: 'Vídeos disponibles',
        moneyAmount: 'Cantidad de dinero',
        videosAmount: 'Cantidad de vídeos',
        status: 'Estado',
        createdAt: 'Creado el',
        transactionTab: 'Histórico de pagos',
        data: 'Datos',
        noResults: 'No hay resultados',
        referredBy: 'Referenciado por',
        referralCode: 'Código de referencia',
        beneficiaryId: 'Beneficiario',
        percentage: 'Porcentaje',
        contributions: 'Contribuciones',
        contributionsError: 'No se ha podido actualizar los datos de las contribuciones',
        beneficiary: 'Beneficiario',
        donationAmount: 'Donación',
        donationPercentage: 'Porcentaje de donación',
        sendInvitations: 'Enviar invitaciones',
        send: 'Enviar',
        invitationCount: 'Número de invitaciones',
        invitationCode: 'Código de invitación',
        invitations: 'Invitaciones',
        usersInvitedCount: 'Invitaciones usadas',
        files: 'Archivos',
        uploadFile: 'Subir archivo',
        docDescription: 'Descripción',
        fileName: 'Nombre del archivo',
        fileDate: 'Fecha de subida',
        invitedBy: 'Invitado por',
        maxSize: 'Máximo 100mb',
        emptyReferredBy: 'Ninguno',
        agency: 'Agencia',
        billingInfo: 'Datos de facturación',
        'billingInfo.name': 'Nombre creador',
        'billingInfo.lastName': 'Apellidos creador',
        'billingInfo.residenceCountry': 'País',
        'billingInfo.residenceAddress': 'Dirección',
        'billingInfo.residenceCity': 'Ciudad',
        'billingInfo.residencePostalCode': 'Código postal',
        'billingInfo.birthDate': 'Fecha de nacimiento',
        'billingInfo.documentType': 'Tipo de documento',
        'billingInfo.companyName': 'Nombre de la empresa',
        'billingInfo.documentNumber': 'Nº de documento',
        'billingInfo.companyAddress': 'Dirección de la empresa',
        'billingInfo.companyEmail': 'Correo de la empresa',
        'billingInfo.companyCity': 'Ciudad de la empresa',
        'billingInfo.companyPostalCode': 'Código postal de la empresa',
        'billingInfo.companyCountry': 'País de la empresa',
        'billingInfo.companyVat': 'Nº identificación de la empresa',
        'billingInfo.companyIban': 'IBAN de la empresa',
        'billingInfo.contractSignDate': 'Fecha de firma del contrato',
        'billingInfo.holdingPhotoDocument': 'Foto con el documento',
        'billingInfo.photoDocument': 'Foto del documento',
        'billingInfo.bankCertificateDocument': 'Cert cuenta empresa',
        'billingInfo.companyCertificateDocument': 'Cert apoderamiento empresa',
        'billingInfo.checked': 'Datos Facturación OK',
        emptyDocument: 'Ninguno',
        passport: 'Pasaporte',
        national_id: 'Documento de identidad',
        invoiceCount: 'Contador de factura',
        noAvailableVideos: 'Sin vídeos disponibles',
        personType: 'Categoría Persona',
        personTypeNatural: 'Física',
        personTypeLegal: 'Jurídica',
        featured: 'Destacado',
        publisherType: 'Tipo de creador',
        publisherTypeOptions: {
          default: 'Por defecto',
          promise: 'Promesa'
        },
        publisherStatus: 'Publish status',
        publisherStatusOptions: {
          allowed: 'Allowed',
          notAllowed: 'Not allowed'
        }
      },
      
      notifications: {
        successInvitations: 'Invitaciones enviadas',
      },
    },
    videos: {
      name: 'Vídeos',
      fields: {
        id: 'Id',
        title: 'Título',
        thumbnail: 'Miniatura',
        url: 'Url',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        originalName: 'Nombre original',
        video: 'Vídeo',
        category: 'Categoría',
        all: 'Todas',
        user: 'Usuario',
        alias: 'Alias',
        owner: 'Propietario',
        'owner.nickname': 'Propietario',
        'category.code': 'Categoría',
        status: 'Estado',
        public: 'Público',
        private: 'Privado',
        deleted: 'Borrado',
        error: 'Error',
        trailer: 'Trailer',
        programmed: 'Programado',
        purchasesCount: 'Compras',
        highlighted: 'Destacado',
        emptyCategory: 'Ninguna',
        free: 'Gratuito',
        maximumNumberOfPurchases: 'Maximum number of purchases',
        totalPurchases: 'Total purchases',
        addPurchases: 'Add purchases',
        purchases: 'purchases',
        popUpTitle: "Attention! You are going to add a total of",
        popUpDesc: 'You are about to make a change to view purchases for this video. Keep in mind that this will affect the visibility and reach of your content. Visits may take a few minutes to be updated.',
        popUpCancel: 'Cancel',
        popUpAccept: 'Accept',
        purchaseButton: 'Purchase',
      },
    },
    categories: {
      name: 'Categorías',
      fields: {
        id: 'Id',
        code: 'Código',
        nameEs: 'Nombre en español',
        nameEn: 'Nombre en inglés',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
      },
    },
    products: {
      name: 'Productos',
      fields: {
        id: 'Id',
        price: 'Precio',
        videosAmount: 'Cantidad de vídeos',
        freeVideosAmount: 'Cantidad de vídeos gratis',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        appStoreId: 'Id App Store',
        playStoreId: 'Id Play Store',
      },
      errors: {
        minZero: 'No puede contener valores negativos',
      },
    },
    'video-reports': {
      name: 'Denuncias(vídeos)',
      fields: {
        id: 'Id',
        reason: 'Razón',
        comment: 'Comentario',
        solved: 'Resuelto',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        informer: 'Denunciante',
        video: 'Vídeo',
        deleteVideo: 'Borrar vídeo',
        successDelete: 'Vídeo borrado',
        confirmDeleteVideo: '¿Está seguro que desea borrar el vídeo?',
        videoDeleted: 'Vídeo borrado',
      },
    },
    'comment-reports': {
      name: 'Denuncias(comentarios)',
      fields: {
        id: 'Id',
        reason: 'Razón',
        comment: 'Comentario',
        solved: 'Resuelto',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        informer: 'Denunciante',
        commenter: 'Usuario del comentario',
        video: 'Vídeo',
        successDelete: 'Comentario borrado',
        deleteComment: 'Borrar comentario',
        confirmDeleteComment: '¿Está seguro que desea borrar el comentario?',
        commentDeleted: 'Comentario borrado',
      },
    },
    beneficiaries: {
      name: 'Beneficiarios',
      fields: {
        id: 'Id',
        nameEs: 'Nombre español',
        nameEn: 'Nombre inglés',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        address: 'Dirección',
        city: 'Ciudad',
        country: 'País',
        contractSignDate: 'Fecha de firma del contrato',
        email: 'Correo',
        iban: 'IBAN',
        postalCode: 'Código postal',
        data: 'Datos',
        files: 'Archivos',
        uploadFile: 'Subir archivo',
        docDescription: 'Descripción',
        fileName: 'Nombre del archivo',
        fileDate: 'Fecha de subida',
        maxSize: 'Máximo 100mb',
        noResults: 'No hay resultados',
        displayedIn: 'Mostrar en',
        availableInContext: 'Mostrar en',
        billing: 'Pantalla de facturación',
        purchase: 'Compras',
        scope: 'Alcance',
        nationalScope: 'Nacional',
        internationalScope: 'Internacional',
        identificationNumber: 'NIF',
      },
    },
    'publisher-earnings': {
      name: 'Ganancias(creador)',
      fields: {
        id: 'Id',
        total: 'Total',
        purchases: 'Compras',
        periodStart: 'Comienzo',
        periodEnd: 'Fin',
        beneficiaryId: 'Beneficiario',
        userId: 'Usuario',
        percentage: 'Porcentaje',
        contributions: 'Contribuciones',
        publisher: 'Creador de contenido',
        publisherId: 'ID Creador',
        type: 'Tipo',
        amount: 'Cantidad',
        items: 'Desglose',
        mgmUser: 'Usuario',
        beneficiary: 'Beneficiario',
        totalVideoRevenue: 'Ingresos videos',
        totalInvitationsRevenue: 'Ingresos invitados',
        totalRevenue: 'Total ingresos',
        donationPercent: 'Donación(%)',
        amountDonation: 'Donación(€)',
        paid: 'Pagado',
        emptyPublisher: 'Ninguno',
        pay: 'Marcar como pagado',
        export: 'Generar informe',
        invoiceNumber: 'Núm. factura',
        agency: 'Agencia',
        publisherUser: 'Creador',
        publisherType: 'Tipo',
      },
      notifications: {
        successPaid: 'Pagos actualizaos',
      },
    },
    'beneficiary-earnings': {
      name: 'Ganancias(beneficiario)',
      fields: {
        id: 'Id',
        total: 'Total',
        periodStart: 'Fecha comienzo',
        periodEnd: 'Fecha fin',
        beneficiarie: 'Beneficiario',
        publisher: 'Usuario',
        amount: 'Cantidad',
        contributions: 'Contribuciones',
        type: 'Tipo',
        publisher_donation: 'Donación de creador',
        user_donation: 'Donación de usuario',
        emptyBeneficiarie: 'Ninguno',
        userCount: 'Total Usuarios',
        publisherCount: 'Total creadores',
        paid: 'Pagado',
        userDonation: 'Donación usuario',
        publisherDonation: 'Donación creador',
      },
    },
    transactions: {
      name: 'Ingresos',
      fields: {
        id: 'Id',
        createdAt: 'Creado el',
        'user.id': 'Id usuario',
        store: 'Store',
        moneyAmount: 'Total',
        status: 'Estado',
        donationAmount: 'Donación',
        videosAmount: 'Cantidad de vídeos',
        startDate: 'Fecha de comienzo',
        endDate: 'Fecha de fin',
        'beneficiary.nameEs': 'Beneficiario',
        currencyCode: 'Divisa',
        countryCode: 'País',
      },
    },
    administrators: {
      name: 'Administradores',
      fields: {
        id: 'Id',
        name: 'Nombre',
        username: 'Nombre de usuario',
        email: 'Email',
        nickname: 'Alias',
        enabled: 'Activo',
        role: 'Rol',
      },
    },
    configurations: {
      name: 'Variables de negocio',
      fields: {
        id: 'Id',
        description: 'Descripción',
        value: 'Valor',
      },
    },
  },
}
