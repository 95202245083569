import React from 'react';
import data from './data.json';
import get from 'lodash/get';
import * as PropTypes from 'prop-types'

/**
 * A React Admin field that displays a value as a currency amount.
 * @param source The value field to display
 * @param currencySource The currency field to use to get the currency
 * @param granularity Amount of precision (most currencies by default use 2)
 * @param record The record that is going to be injected by the view
 * @returns {JSX.Element}
 * @constructor
 */
const CurrencyField = ({ source, currencySource, granularity, record = {} }) => {
  const value = get(record, source);
  const currency = get(record, currencySource);
  const symbol = currency ? data[currency]?.symbols[0] : 'x';
  const div = Math.pow(10, granularity);

  return <span>{symbol + value/div}</span>;
}

CurrencyField.propTypes = {
  source: PropTypes.string.isRequired,
  currencySource: PropTypes.string,
  granularity: PropTypes.number,
  record: PropTypes.object,
};
CurrencyField.defaultProps = {
  addLabel: true,
  currencySource: 'currency',
  granularity: 6
};
export default CurrencyField;
