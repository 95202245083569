import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { RegularInput, RegularButton } from '../../atoms'
import { Typography, CircularProgress } from '@material-ui/core'
import { withTranslate, useNotify } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as API from '../../../api'
import { Auth } from 'aws-amplify'
import _ from 'lodash'
import theme from '../../../config/theme'

const ChangeNameForm = ({ translate }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [name, setName] = useState('')
  const [isFetchingName, setIsFetchingName] = useState(false)
  const [disabledButton, setDisabledButton] = useState(true)
  const notify = useNotify()

  useEffect(() => {
    if (name) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [name])

  useEffect(() => {
    async function getName() {
      setIsFetchingName(true)
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
      const name = _.get(user, 'attributes.name', '')
      setIsFetchingName(false)
      setName(name)
    }

    getName()
  }, [])

  const onSubmit = async e => {
    e.preventDefault()
    setIsFetching(true)
    try {
      await API.changeMeData({ name })
      notify('myProfile.dataChanged', 'info')
    } catch (error) {
      notify('generalError', 'warning')
    }
    setIsFetching(false)
  }

  const handleName = value => {
    setName(value)
  }

  return (
    <MuiThemeProvider theme={theme}>
      {isFetchingName ? (
        <div style={styles.spinnerWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={onSubmit} autoComplete='off'>
          <Typography style={styles.title} variant='body1' color='inherit'>
            {translate('myProfile.changeName')}
          </Typography>
          <RegularInput value={name} onChange={handleName} label={translate('myProfile.userName')} />
          <div style={styles.btnWrapper}>
            <RegularButton
              isFetching={isFetching}
              disabled={disabledButton}
              type='submit'
              label={translate('myProfile.change')}
            />
          </div>
        </form>
      )}
    </MuiThemeProvider>
  )
}

ChangeNameForm.propTypes = {
  translate: PropTypes.func,
}

const enharce = compose(withRouter, withStyles(styles), withTranslate)

export default enharce(ChangeNameForm)
