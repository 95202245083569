export default {
  pagination: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '30px',
    paddingBottom: '15px',
  },
  uploadWrapper: {
    maxWidth: 400,
    marginBottom: '30px',
  },
  fileInput: {
    marginBottom: '5px',
  },
  btnUpload: {
    maxWidth: 150,
  },
  spinnerWrapper: {
    textAlign: 'center',
    padding: '30px 15px',
  },
  downloadIcon: {
    width: '30px',
    cursor: 'pointer',
  },
  descriptionWrapper: {
    marginBottom: '10px',
  },
  fileWrapper: {
    marginBottom: '15px',
  },
}
