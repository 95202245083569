import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslate } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import * as API from '../../../api'
import {
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableHead,
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination'
import styles from './styles'

const LIMIT = 5
const PAGE = 1
const TOTAL = 0

class UserTransactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transactionsData: [],
      transactionsLimit: LIMIT,
      transactionsPage: PAGE,
      transactionsTotal: TOTAL,
      isFetchingTransaction: false,
    }
  }

  async componentDidMount() {
    let { transactionsData, transactionsTotal, transactionsPage, transactionsLimit } = this.state
    this.getUserTransactionData(transactionsData, transactionsTotal, transactionsPage, transactionsLimit)
  }

  handleChangeTransactionPage = async (event, transactionsPage) => {
    let { transactionsData, transactionsTotal, transactionsLimit } = this.state
    this.getUserTransactionData(transactionsData, transactionsTotal, transactionsPage, transactionsLimit)
  }

  getUserTransactionData = async (transactionsData, transactionsTotal, transactionsPage, transactionsLimit) => {
    try {
      const offset = transactionsLimit * (transactionsPage - 1)
      this.setState({ isFetchingTransaction: true })
      const { userId } = this.props
      const response = await API.getTransationsByUser({
        userId,
        limit: transactionsLimit,
        page: transactionsPage,
        offset,
      })
      transactionsData = _.get(response, 'data.data', '')
      transactionsTotal = _.get(response, 'data.total', '')
      transactionsPage = _.get(response, 'data.page', '')
      this.setState({ transactionsData, transactionsTotal, transactionsPage, isFetchingTransaction: false })
    } catch {
      this.setState({ isFetchingTransaction: false })
    }
  }

  render() {
    const {
      transactionsData,
      isFetchingTransaction,
      transactionsTotal,
      transactionsPage,
      transactionsLimit,
    } = this.state
    const { translate } = this.props
    const totalTransactionPages = Math.ceil(transactionsTotal / transactionsLimit)

    const formatTransaction = _.map(transactionsData, (transactionItem, key) => {
      const moneyAmount = _.get(transactionItem, 'moneyAmount', 0)
      const videosAmount = _.get(transactionItem, 'videosAmount', 0)
      const donationAmount = _.get(transactionItem, 'donationAmount', 0)
      const donationPercentage = _.get(transactionItem, 'donationPercentage', 0)
      const status = _.get(transactionItem, 'status', '')
      const createdAt = transactionItem.createdAt ? moment(transactionItem.createdAt).format('DD/MM/YYYY HH:mm') : ''
      const updatedAt = transactionItem.updatedAt ? moment(transactionItem.updatedAt).format('DD/MM/YYYY HH:mm') : ''
      const beneficiary = _.get(transactionItem, 'beneficiary.nameEs', '')

      return (
        <TableRow key={key}>
          <TableCell>{moneyAmount / 100}€</TableCell>
          <TableCell>{videosAmount}</TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{beneficiary}</TableCell>
          <TableCell>{donationAmount / 100}€</TableCell>
          <TableCell>{donationPercentage}%</TableCell>
          <TableCell>{createdAt}</TableCell>
          <TableCell>{updatedAt}</TableCell>
        </TableRow>
      )
    })

    return (
      <div style={styles.tabWrapper}>
        {isFetchingTransaction ? (
          <div style={styles.spinnerWrapper}>
            <CircularProgress />
          </div>
        ) : transactionsData?.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{translate('resources.users.fields.moneyAmount')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.videosAmount')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.status')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.beneficiary')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.donationAmount')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.donationPercentage')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.createdAt')}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{translate('resources.users.fields.updatedAt')}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{formatTransaction}</TableBody>
              </Table>
            </TableContainer>

            <Pagination
              style={styles.pagination}
              onChange={this.handleChangeTransactionPage}
              page={transactionsPage}
              count={totalTransactionPages}
              color='primary'
            />
          </>
        ) : (
          <Typography variant='body2' component='p'>
            {translate('resources.users.fields.noResults')}
          </Typography>
        )}
      </div>
    )
  }
}

UserTransactions.propTypes = {
  translate: PropTypes.func,
  userId: PropTypes.string,
}

const enharce = compose(withStyles(styles), withTranslate)

export default enharce(UserTransactions)
