import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { RegularInput, RegularButton } from '../../atoms'
import { withTranslate, useRedirect, showNotification, Notification } from 'react-admin'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import _ from 'lodash'
import { connect } from 'react-redux'
import { validatePassword, isAdminUser, isSuperAdminUser } from '../../../utils'
import jwtDecode from 'jwt-decode'

const CompletePasswordForm = ({ translate, showNotification }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [user, setUser] = useState('')
  const [tempPass, setTempPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const redirect = useRedirect()

  useEffect(() => {
    if (user && tempPass && newPass) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  }, [user, newPass, tempPass])

  const onSubmit = async () => {
    if (validatePassword(newPass)) {
      setIsFetching(true)
      try {
        const userResponse = await Auth.signIn(user, tempPass)

        if (userResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(userResponse, newPass)
          const loginResponse = await Auth.signIn({ username: user, password: newPass })
          const token = _.get(loginResponse, 'signInUserSession.accessToken.jwtToken', '')

          if (token) {
            const decoded = jwtDecode(token)
            const permissions = _.get(decoded, 'cognito:groups')
            const isRoleAdmin = isAdminUser(permissions)
            const isRoleSuperAdmin = isSuperAdminUser(permissions)

            if (isRoleAdmin || isRoleSuperAdmin) {
              localStorage.setItem('token', token)
            }
          }

          showNotification(translate('passwordChanged'))
          goToLogin()
        }
      } catch {
        showNotification(translate('generalError'), 'warning')
      }
      setIsFetching(false)
    } else {
      showNotification(translate('completePassword.validatePassword'), 'warning')
    }
  }

  const goToLogin = () => {
    redirect('login')
  }

  const handleChangeUser = value => {
    setUser(value)
  }

  const handleTempPass = value => {
    setTempPass(value)
  }

  const handleNewPass = value => {
    setNewPass(value)
  }

  return (
    <div>
      <form onSubmit={onSubmit} autoComplete='off'>
        <Typography style={styles.title} variant='body1' component='p'>
          {translate('completePassword.title')}
        </Typography>
        <RegularInput onChange={handleChangeUser} label={translate('completePassword.user')} />
        <RegularInput onChange={handleTempPass} label={translate('completePassword.tempPass')} type='password' />
        <RegularInput onChange={handleNewPass} label={translate('completePassword.newPass')} type='password' />
        <div style={styles.btnsWrapper}>
          <div style={styles.btnWrapper}>
            <RegularButton
              isFetching={isFetching}
              disabled={disabledButton}
              type='submit'
              label={translate('completePassword.send')}
            />
          </div>
          <Typography onClick={goToLogin} style={styles.back} variant='body1' component='span'>
            {translate('completePassword.back')}
          </Typography>
        </div>
      </form>
      <Notification autoHideDuration={5000} />
    </div>
  )
}

CompletePasswordForm.propTypes = {
  translate: PropTypes.func,
  showNotification: PropTypes.func,
}

const enharce = compose(
  connect(null, {
    showNotification,
  }),
  withStyles(styles),
  withTranslate
)

export default enharce(CompletePasswordForm)
