import React from 'react'
import { Admin, Resource } from 'react-admin'
import PropTypes from 'prop-types'
import admin from '../../../config/admin'
import {
  Users,
  Videos,
  Categories,
  Products,
  VideoReports,
  CommentReports,
  Beneficiaries,
  PublisherEarnings,
  BeneficiaryEarnings,
  Administrators,
  Transactions,
  Configurations, Pricing, Purchases,
} from '../../pages'
import { isSuperAdminUser, isMarketingUser } from '../../../utils'
import Amplify, { Auth } from 'aws-amplify'
import { AWS_CONFIG, AUTH_CONFIG } from '../../../config/aws'

Amplify.configure(AWS_CONFIG)
Auth.configure({ oauth: AUTH_CONFIG })

const CustomResource = ({ renderResource, ...props }) => {
  if (renderResource) {
    return <Resource {...props} />
  }
  return null
}

CustomResource.defaultProps = {
  renderResource: true,
}

CustomResource.propTypes = {
  renderResource: PropTypes.bool,
}

const App = () => (
  <Admin {...admin}>
    {permissions => {
      return [
        <CustomResource
          key='configurations'
          name='configurations'
          {...Configurations}
          renderResource={isSuperAdminUser(permissions)}
        />,
        <CustomResource
          key='administrators'
          name='administrators'
          {...Administrators}
          renderResource={isSuperAdminUser(permissions)}
        />,
        <CustomResource key='users' name='users' {...Users} renderResource={!isMarketingUser(permissions)} />,
        <CustomResource key='videos' name='videos' {...Videos} />,
        <CustomResource key='categories' name='categories' {...Categories} />,
        <CustomResource
          key='pricings'
          name='pricings'
          {...Pricing}
          renderResource={isSuperAdminUser(permissions)}
        />,
        <CustomResource key='products' name='products' {...Products} renderResource={!isMarketingUser(permissions)} />,
        <CustomResource key='video-reports' name='video-reports' {...VideoReports} />,
        <CustomResource key='comment-reports' name='comment-reports' {...CommentReports} />,
        <CustomResource
          key='beneficiaries'
          name='beneficiaries'
          {...Beneficiaries}
          renderResource={!isMarketingUser(permissions)}
        />,
        <CustomResource
          key='publisher-earnings'
          name='publisher-earnings'
          {...PublisherEarnings}
          renderResource={!isMarketingUser(permissions)}
        />,
        <CustomResource
          key='beneficiary-earnings'
          name='beneficiary-earnings'
          {...BeneficiaryEarnings}
          renderResource={!isMarketingUser(permissions)}
        />,
        <CustomResource
          key='transactions'
          name='transactions'
          {...Transactions}
          renderResource={!isMarketingUser(permissions)}
        />,
      <CustomResource
        key='purchases'
        name='purchases'
        {...Purchases}
        renderResource={!isMarketingUser(permissions)}
      />,
        <CustomResource key='documents' name='documents' />,
      ]
    }}
  </Admin>
)

export default App
