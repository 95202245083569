import authProvider from '../providers/authProvider'
import dataProvider from '../providers/dataProvider'
import i18nProvider from '../providers/i18nProvider'
import layout from '../components/system/layout'
import loginPage from '../components/pages/login'
import theme from './theme'
import customRoutes from '../components/system/routes/customRoutes'

export default {
  authProvider,
  dataProvider: dataProvider(),
  i18nProvider,
  layout,
  theme,
  loginPage,
  customRoutes,
}
