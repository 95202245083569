import React from 'react'
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { Filter, TextInput, List } from 'react-admin'
import { ReadFields } from '../commons'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }
const PricingFilter = (props) => (
  <Filter {...props}>
    <TextInput source='country' alwaysOn />
    <TextInput source='store' alwaysOn />
    <TextInput source='product' alwaysOn />
    <TextInput source='productID' label='productID' alwaysOn />
  </Filter>
);

const list = props => (
  <List {...props} sort={DEFAULT_SORT} filters={<PricingFilter />}>
    <CustomizableDatagrid rowClick='show'>
      {ReadFields()}
    </CustomizableDatagrid>
  </List>
)

export default list
