import React, { Component } from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import SettingsIcon from '@material-ui/icons/Settings'
import PropTypes from 'prop-types'
import { UserMenu, MenuItemLink, withTranslate } from 'react-admin'

class MyUserMenuView extends Component {
  render() {
    const { classes, translate, ...props } = this.props

    return (
      <div className={classes.root}>
        <UserMenu {...props}>
          <MenuItemLink
            className={classes.menuItem}
            to='/my-profile'
            primaryText={translate('myProfile.title')}
            leftIcon={<SettingsIcon />}
          />
        </UserMenu>
      </div>
    )
  }
}

MyUserMenuView.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func,
}

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      '& > span': {
        marginRight: '6px',
      },
      '& li': {
        color: 'black',
        '& span': {
          paddingRight: '6px',
        },
      },
    },
    menuItem: {
      color: 'rgba(0, 0, 0, 0.54)',
      '& *': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  })

const enharce = compose(withTranslate, withStyles(styles))

export default enharce(MyUserMenuView)
