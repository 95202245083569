import React from 'react'
import PropTypes from 'prop-types'
import { Datagrid, TextField, List, useTranslate, ReferenceField } from 'react-admin'
import { showIfMarketingUser } from '../../../../utils'

const DEFAULT_SORT = { field: 'createdAt', order: 'DESC' }

const CustomList = props => {
  const translate = useTranslate()
  const { permissions } = props

  return (
    <List {...props} sort={DEFAULT_SORT} exporter={false} bulkActionButtons={false}>
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <TextField source='reason' />
        <TextField source='comment' />

        {showIfMarketingUser(permissions) ? (
          <TextField label={translate('resources.comment-reports.fields.informer')} source='user.nickname' />
        ) : (
          <ReferenceField
            label={translate('resources.comment-reports.fields.informer')}
            link='show'
            source='user.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>
        )}

        {showIfMarketingUser(permissions) ? (
          <TextField label={translate('resources.comment-reports.fields.commenter')} source='commenter.nickname' />
        ) : (
          <ReferenceField
            label={translate('resources.comment-reports.fields.commenter')}
            link='show'
            source='commenter.id'
            reference='users'
          >
            <TextField source='nickname' />
          </ReferenceField>
        )}

        <ReferenceField
          label={translate('resources.comment-reports.fields.video')}
          link='show'
          source='video.id'
          reference='videos'
        >
          <TextField source='title' />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

CustomList.propTypes = {
  permissions: PropTypes.array,
}

const list = props => <CustomList {...props} />

export default list
