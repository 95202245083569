import * as React from 'react'
import { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useListContext, TopToolbar, ExportButton, sanitizeListRestProps, useTranslate } from 'react-admin'

const ListActions = props => {
  const translate = useTranslate()
  const { className, filters, maxResults, ...rest } = props
  const { currentSort, resource, displayedFilters, filterValues, showFilter, total } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        label={translate('resources.publisher-earnings.fields.export')}
      />
    </TopToolbar>
  )
}

ListActions.propTypes = {
  maxResults: PropTypes.number,
  filters: PropTypes.object,
  className: PropTypes.string,
}

export default ListActions
